<template>
	<div>
		<el-form ref="elForm" :model="formData" :rules="rules" size="small" label-width="100px">
			<el-form-item label="名称" prop="name">
				<el-input v-model="formData.name" placeholder="请输入名称" clearable :style="{ width: '100%' }"></el-input>
			</el-form-item>
			<el-form-item label="父级">
				<el-select v-model="formData.pid" placeholder="请选择父级" class="select-item">
					<el-option v-for="(item, index) in bsclassData" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item>
				<div style="text-align: right">
					<span class="dialog-footer">
						<el-button @click="formCancel">取 消</el-button>
						<el-button type="primary" @click="formSubmit">确 定</el-button>
					</span>
				</div>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { bsclassEditService, bsclassListService, bsclassAddService } from '@s/base/BSclassService';
import { deleteTreeNodeById } from '@u/htools.tree';

export default {
	props: ['selectData_p', 'isRefreshList_p'],
	data() {
		return {
			editId: -1,
			formData: {
				name: '',
				pid: null
			},
			rules: {
				name: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				]
			},
			bsclassData: null
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				this.bsclassData = null;
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.bsclassData === null && (await this.bsclassList());
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.bsclassList();
	},
	methods: {
		async bsclassList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000,
				type: 0
			};
			const res = await bsclassListService(dataJson);
			if (this.editId === -1) {
				this.bsclassData = [...res];
			} else {
				this.bsclassData = deleteTreeNodeById(res, this.editId, 'id');
			}
		},
		formSubmit() {
			this.$refs.elForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await bsclassAddService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await bsclassEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>
<style>
</style>
