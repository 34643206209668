<template>
	<div>
		<el-form ref="elForm" :model="formData" :rules="rules" size="small" label-width="130px">
			<el-form-item label="设备类型" prop="deviceNameId">
				<!-- <el-select v-model="formData.deviceNameId" placeholder="请选择设备类型" class="select-item">
					<el-option v-for="(item, index) in deviceTypeData" :key="index" :label="item.deviceType" :value="item.id"></el-option>
				</el-select> -->
				<el-select v-model="parentName" class="select-item" placeholder="请选择" collapse-tags clearable @clear="clearHandler">
					<el-option :value="formData.deviceNameId" style="height: auto">
						<el-tree
							:data="deviceTypeData"
							node-key="id"
							ref="tree"
							highlight-current
							:props="defaultProps"
							accordion
							@node-click="nodeClickHandler"
						></el-tree>
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="故障名称" prop="name">
				<el-input v-model="formData.name" placeholder="请输入故障名称" clearable :style="{ width: '100%' }"></el-input>
			</el-form-item>
			<el-form-item>
				<div style="text-align: right">
					<span class="dialog-footer">
						<el-button @click="formCancel">取 消</el-button>
						<el-button type="primary" @click="formSubmit">确 定</el-button>
					</span>
				</div>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { faultAddService, faultEditService } from '@s/base/FaultService';
import { deviceTypeListService } from '@s/base/DeviceTypeService';
import { getTreeNodeById, changeTreeDataToChildren } from '@u/htools.tree';

export default {
	components: {},
	props: ['selectData_p', 'isRefreshList_p', 'deviceTypeId_p'],
	data() {
		return {
			editId: -1,
			parentName: '',
			defaultProps: {
				children: 'children',
				label: 'deviceType'
			},
			deviceTypeData: null,
			formData: {
				name: '',
				deviceNameId: null
			},
			rules: {
				name: [
					{
						required: true,
						message: '请输入故障名称',
						trigger: 'blur'
					}
				],
				deviceNameId: [
					{
						required: true,
						message: '请选择设备类型',
						trigger: 'change'
					}
				]
			}
		};
	},
	computed: {},
	watch: {
		deviceTypeId_p: {
			async handler(newValue) {
				this.formData.deviceNameId = newValue;
				this.deviceTypeData === null && (await this.deviceTypeList());
				getTreeNodeById(this.deviceTypeData, 'id', newValue, node => {
					this.parentName = node.deviceType;
				});
			},
			deep: true,
			immediate: true
		},
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.formData = { ...newValue[0] };
					this.deviceTypeData === null && (await this.deviceTypeList());
					/* const result = arrayIncludeItem(this.deviceTypeData, newValue[0].deviceNameId);
					if (!result) {
						this.formData.deviceNameId = null;
					} */
					getTreeNodeById(this.deviceTypeData, 'id', this.formData.deviceNameId, node => {
						this.parentName = node.deviceType;
					});
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.deviceTypeList();
	},
	methods: {
		clearHandler() {
			this.formData.deviceNameId = null;
			this.parentName = '';
		},
		async deviceTypeList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000,
				type: -1
			};
			const res = await deviceTypeListService(dataJson);
			this.deviceTypeData = changeTreeDataToChildren([...res.records]);
		},
		formSubmit() {
			this.$refs.elForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await faultAddService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await faultEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		},
		nodeClickHandler(data) {
			if (data.pid !== 0) {
				this.formData.deviceNameId = data.id;
				this.parentName = data.deviceType;
			}
		}
	}
};
</script>
<style>
</style>
