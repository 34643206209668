import { hgetRequest, hpostRequest } from '@u/htools.axios';
import { changeTreeDataToChildren } from '@u/htools.tree';

/*
 *@Description: 权限列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-07 10:37:06
 */
export const adminAuthorizeListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hlxpeng/jurisdiction/jurisdictionAllQuery', dataJson);
			resolve(changeTreeDataToChildren(res));
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 权限添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-07 10:37:19
 */
export const adminAuthorizeAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				powerCode: '', // 权限标识
				powerName: '', // 权限名称
				remark: '',
				state: '',
				parentId: '', // 权限父级
				powerSort: '', // 权限排序
				...data
			};
			const res = await hpostRequest('hlxpeng/jurisdiction/jurisdictionAdd', dataJson);
			console.log(res);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 权限编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-07 10:39:49
 */
export const adminAuthorizeEditService = (powerCode, data) => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				powerCode: '', // 权限标识
				powerName: '', // 权限名称
				remark: '',
				state: '',
				parentId: '', // 权限父级
				powerSort: '', // 权限排序
				...data
			};
			const res = await hpostRequest(`hlxpeng/jurisdiction/jurisdictionUpdate?powerCode=${powerCode}`, dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 权限删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-07 13:22:39
 */
export const adminAuthorizeDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				powerCode: [],
				...data
			};
			const res = await hpostRequest('hlxpeng/jurisdiction/jurisdictionDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 权限详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-11 10:36:01
 */
export const adminAuthorizeDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				powerCode: '',
				...data
			};
			const res = await hgetRequest('hlxpeng/jurisdiction/jurisdictionByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default adminAuthorizeListService;
