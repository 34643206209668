<template>
	<div class="head-serch">
		<el-form class="form" ref="adminUserForm" :model="adminUserSearchForm" label-width="100px" @submit.native.prevent @keyup.enter.native="searchHandler">
			<el-form-item label="用户名称">
				<el-input v-model="adminUserSearchForm.userName" size="mini" placeholder="请输入用户名称"></el-input>
			</el-form-item>
			<el-form-item label="企业" prop="name" v-if="currentUserOrganization === 0">
				<el-select v-model="adminUserSearchForm.organizationId" placeholder="请选择" class="select-item" clearable filterable>
					<el-option
						v-for="(enterprise, index) in enterpriseData"
						:key="index"
						:label="enterprise.organizationName"
						:value="enterprise.id"
					></el-option>
				</el-select>
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click.enter="searchHandler">搜索</el-button>
	</div>
</template>

<script>
// import { enterpriseListService } from '@s/base/EnterpriseService';
/* import { hgetStorage } from '@/util/htools.web'; */

export default {
	data() {
		return {
			// 搜索
			adminUserSearchForm: {
				userName: '',
				organizationId: ''
			},
			currentUserOrganization: '',
			enterpriseData: []
		};
	},
	created() {
		// this.currentUserOrganization = hgetStorage('organization');
		// this.enterpriseList();
	},
	methods: {
		async enterpriseList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await enterpriseListService(dataJson);
			this.enterpriseData = res.records;
		},
		searchHandler() {
			this.$emit('searchForm', this.adminUserSearchForm);
		}
	}
};
</script>

<style lang="less" scoped>
</style>
