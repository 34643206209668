import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 68大小类列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54
 */
export const bsclassListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				type: 10,
				...data
			};
			const res = await hpostRequest('hlzlong/sizeClasses/sizeClassesAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 68大小类添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:00:29
 */
export const bsclassAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				pid: '',
				...data
			};
			const res = await hpostRequest('hlzlong/sizeClasses/sizeClassesAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 68大小类编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:58
 */
export const bsclassEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
				pid: '',
				...data
			};
			const res = await hpostRequest('hlzlong/sizeClasses/sizeClassesUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 标准名称详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:59:21
 */
export const bsclassDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				sizeClassesId: '',
				...data
			};
			const res = await hgetRequest('hlzlong/sizeClasses/sizeClassesByIdQuery', dataJson);
			res.pid = res.pid === 0 ? null : Number(res.pid);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 68大小类删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:03
 */
export const bsclassDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest('hlzlong/sizeClasses/sizeClassesDeleteById', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default bsclassListService;
