<template>
	<div class="head-serch">
		<el-form class="form" ref="searchFormDom" :model="searchForm" label-width="100px" @submit.native.prevent @keyup.enter.native="searchHandler">
			<el-form-item label="名称">
				<el-input v-model="searchForm.name" placeholder="请输入名称" />
			</el-form-item>
			<el-form-item label="键">
				<el-input v-model="searchForm.paramKey" placeholder="请输入键" />
			</el-form-item>
			<el-form-item label="值">
				<el-input v-model="searchForm.paramValue" placeholder="请输入值" />
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="searchHandler">搜索</el-button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			searchForm: {
				name: '',
				paramKey: '',
				paramValue: ''
			}
		};
	},
	created() {},
	methods: {
		searchHandler() {
			this.$emit('searchForm', this.searchForm);
		}
	}
};
</script>

<style>
.form {
	display: flex;
}
</style>
