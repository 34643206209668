<template>
	<el-row>
		<el-col :span="24">
			<el-form ref="adminAuthorizeForm" :model="adminAuthorizeForm" :rules="adminAuthorizeRules" label-width="66px">
				<el-form-item label="名称" prop="powerName">
					<el-input v-model="adminAuthorizeForm.powerName" placeholder="请输入权限名称" />
				</el-form-item>
				<el-form-item label="标识" prop="powerCode">
					<el-input v-model="adminAuthorizeForm.powerCode" placeholder="请输入权限标识" />
				</el-form-item>
				<el-form-item label="排序" prop="powerSort">
					<el-input v-model="adminAuthorizeForm.powerSort" type="number" placeholder="请输入排序" />
				</el-form-item>
				<el-form-item label="状态" prop="state">
					<el-radio-group style="width: 200px" v-model="adminAuthorizeForm.state">
						<el-radio :label="0">正常</el-radio>
						<el-radio :label="1">禁用</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="父级">
					<el-select v-model="parentName" class="select-item" placeholder="请选择" collapse-tags clearable @clear="clearHandler">
						<el-option :value="adminAuthorizeForm.parentId" style="height: auto">
							<el-tree
								:data="adminAuthorizeData"
								node-key="powerCode"
								ref="tree"
								highlight-current
								:props="defaultProps"
								@node-click="nodeClickHandler"
							></el-tree>
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="备注">
					<el-input type="textarea" :rows="4" v-model="adminAuthorizeForm.remark" placeholder="请输入备注" />
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer">
				<el-button @click="adminAuthorizeFormCancel">取 消</el-button>
				<el-button type="primary" @click="adminAuthorizeFormSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
import { adminAuthorizeAddService, adminAuthorizeEditService, adminAuthorizeListService } from '@s/admin/AdminAuthorizeService';
import { deleteTreeNodeById, getTreeNodeById } from '@u/htools.tree';

export default {
	props: ['selectData_p'],
	data() {
		return {
			editId: -1,
			adminAuthorizeForm: {
				powerName: '',
				state: 0,
				remark: '',
				powerCode: '',
				parentId: 0
			},
			powerCode: '',
			adminAuthorizeRules: {
				powerName: [
					{
						required: true,
						message: '请输入权限名称',
						trigger: 'blur'
					}
				],
				powerCode: [
					{
						required: true,
						message: '请输入权限标识',
						trigger: 'blur'
					}
				],
				powerSort: [
					{
						required: true,
						message: '请输入权限排序',
						trigger: 'blur'
					}
				],
				state: [
					{
						required: true,
						message: '请选择权限状态',
						trigger: 'change'
					}
				],
				parentId: [
					{
						required: true,
						message: '请选择权限父级',
						trigger: 'blur'
					}
				]
			},
			parentName: '',
			defaultProps: {
				children: 'children',
				label: 'powerName'
			},
			adminAuthorizeData: []
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].powerCode;
					/* this.parentName = newValue[0].powerName; */
					this.adminAuthorizeData.length === 0 && (await this.adminAuthorizeList());
					getTreeNodeById(this.adminAuthorizeData, 'powerCode', newValue[0].parentId, node => {
						// console.log(this.adminAuthorizeData, newValue[0].parentId, node);
						this.parentName = node.powerName;
					});
					this.adminAuthorizeForm = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.editId === -1 && this.adminAuthorizeList();
	},
	methods: {
		clearHandler() {
			this.adminAuthorizeForm.parentId = 0;
			this.parentName = '';
		},
		async adminAuthorizeList() {
			const dataJson = {};
			const res = await adminAuthorizeListService(dataJson);
			if (this.editId === -1) {
				this.adminAuthorizeData = res;
			} else {
				this.adminAuthorizeData = deleteTreeNodeById(res, this.editId, 'powerCode');
			}
		},
		adminAuthorizeFormSubmit() {
			this.$refs.adminAuthorizeForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await adminAuthorizeAddService(this.adminAuthorizeForm);
					} else {
						this.powerCode = this.adminAuthorizeForm.powerCode;
						this.adminAuthorizeForm.powerCode = this.editId;
						const dataJson = {
							id: this.editId,
							powerCode: this.editId,
							...this.adminAuthorizeForm
						};
						await adminAuthorizeEditService(this.powerCode, dataJson);
					}
					this.$emit('update:isRefreshList_p', true);
					this.adminAuthorizeFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		adminAuthorizeFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		},
		nodeClickHandler(data) {
			this.adminAuthorizeForm.parentId = data.powerCode;
			this.parentName = data.powerName;
		}
	}
};
</script>

<style>
</style>
