<template>
	<div class="head-serch">
		<el-form
			class="form"
			ref="searchFormDom"
			:model="searchForm"
			label-width="90px"
			@submit.native.prevent
			@keyup.enter.native="searchHandler"
		>
			<el-form-item label="类目名称">
				<el-input v-model="searchForm.name" placeholder="请输入类目名称" />
			</el-form-item>
		</el-form>
		<el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="searchHandler">搜索</el-button>
	</div>
</template>

<script>
export default {
	data() {
		return {
			searchForm: {
				name: ''
			}
		};
	},
	created() {},
	methods: {
		searchHandler() {
			this.$emit('searchForm', this.searchForm);
		}
	}
};
</script>

<style>
.form {
	display: flex;
}
</style>
