<template>
	<el-row>
		<el-col :span="24">
			<el-form ref="adminUserForm" :model="adminUserForm" :rules="adminUserRules" size="small" label-width="120px">
				<el-form-item label="用户名" prop="userName">
					<el-input v-model="adminUserForm.userName" placeholder="请输入用户名" />
				</el-form-item>
				<el-form-item label="密码" prop="userPwd" v-if="editId === -1">
					<el-input type="password" v-model="adminUserForm.userPwd" placeholder="请输入密码" />
				</el-form-item>
				<!-- <el-form-item label="所属企业" prop="organizationId">
					<el-select
						v-model="adminUserForm.organizationId"
						placeholder="请选择"
						class="select-item"
						clearable
						filterable
						:disabled="orgDisabled"
						@change="changeOrgHandler"
					>
						<el-option label="无" :value="0"></el-option>
						<el-option
							v-for="(enterprise, index) in enterpriseData"
							:key="index"
							:label="enterprise.organizationName"
							:value="enterprise.id"
						></el-option>
					</el-select>
				</el-form-item> -->
				<!-- <el-form-item label="大屏查看区域" prop="lookArea">
					<el-select v-model="adminUserForm.lookArea" placeholder="请选择" class="select-item" clearable filterable multiple @change="changeHandle">
						<el-option v-for="(item, index) in areaData" :key="index" :label="item.name" :value="item.id" :disabled="item.disabled"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="昵称" prop="nickName">
					<el-input v-model="adminUserForm.nickName" placeholder="请输入昵称" />
				</el-form-item>
				<template>
					<!-- <el-form-item label="角色" v-if="adminUserForm.roleList.indexOf($envConfig.factoryAdminRoleId) !== -1">
						<el-input value="工厂管理员" readonly="" />
					</el-form-item>
					<el-form-item label="角色" v-else-if="adminUserForm.roleList.indexOf($envConfig.superAdminRoleId) !== -1">
						<el-input value="管理员" readonly="" />
					</el-form-item> -->
					<!-- v-if="
							adminUserForm.roleList.indexOf($envConfig.factoryAdminRoleId) === -1 &&
							adminUserForm.roleList.indexOf($envConfig.superAdminRoleId) === -1
						" -->
					<el-form-item label="角色" prop="roleList">
						<el-select
							ref="multiSelect"
							v-model="adminUserForm.roleList"
							multiple
							filterable
							placeholder="请选择角色"
							class="select-item"
							clearable
						>
							<el-option v-for="(role, index) in roleData" :key="index" :label="role.roleName" :value="role.id"></el-option>
						</el-select>
					</el-form-item>
				</template>
				<el-form-item label="状态" prop="state">
					<el-radio-group style="width: 200px" v-model="adminUserForm.state">
						<el-radio :label="0">启用</el-radio>
						<el-radio :label="1">不启用</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="备注">
					<el-input type="textarea" :rows="4" v-model="adminUserForm.remark"></el-input>
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer" style="text-align: right">
				<el-button @click="adminUserFormCancel">取 消</el-button>
				<el-button type="primary" @click="adminUserFormSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
// eslint-disable-next-line import/named
import { adminUserAddService, adminUserEditService, adminUserRoleService } from '@s/admin/AdminUserService';
// eslint-disable-next-line import/no-cycle
import { adminRoleListService } from '@s/admin/AdminRoleService';
/* import { hgetStorage } from '@/util/htools.web'; */
import { arrayIncludeItem } from '@u/index';

export default {
	props: ['selectData_p', 'organizationId_p', 'editId_p'],
	data() {
		return {
			editId: -1,
			// 弹窗
			adminUserForm: {
				userName: '',
				userPwd: '',
				nickName: '',
				state: 0,
				organizationId: '0',
				lookArea: [],
				remark: '',
				roleList: []
			},
			areaData: [
				{
					id: 0,
					name: '无权限查看'
				},
				{
					id: 1,
					name: '贵溪市'
				},
				{
					id: 2,
					name: '余江区'
				},
				{
					id: 3,
					name: '高新区'
				}
			],
			adminUserRules: {
				userName: [
					{ required: true, message: '请输入用户名', trigger: 'blur' },
					{ min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
				],
				userPwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
				nickName: [{ required: true, message: '请输入用户昵称', trigger: 'blur' }],
				state: [{ required: true, message: '请选择状态', trigger: 'change' }],
				roleList: [
					{
						required: true,
						message: '请选择角色',
						trigger: 'change'
					}
				],
				organizationId: [
					{
						required: true,
						message: '请选择企业',
						trigger: 'change'
					}
				],
				lookArea: [
					{
						required: true,
						message: '请选择大屏查看区域',
						trigger: 'change'
					}
				]
			},
			roleData: [],
			enterpriseData: [],
			currentUserOrganization: null
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.roleData.length === 0 && (await this.roleList());
					// newValue[0].organizationId = newValue[0].organization === null ? 0 : newValue[0].organization.id;
					this.adminUserForm = { ...newValue[0] };
					this.$set(this.adminUserForm, 'roleList', newValue[0].userRoles);
					newValue[0].userRoles.forEach(item => {
						const result = arrayIncludeItem(this.roleData, item);
						if (!result) {
							const index = newValue[0].userRoles.indexOf(item);
							newValue[0].userRoles.splice(index, 1);
							this.$set(this.adminUserForm, 'roleList', newValue[0].userRoles);
						}
					});
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		if (this.editId === -1) {
			// this.adminUserForm.organizationId = 0;
			this.roleList();
		} else if (this.organizationId_p) {
			this.adminUserForm.organizationId = this.organizationId_p;
		}
	},
	methods: {
		// 选择企业重新获取角色
		async changeOrgHandler(e) {
			this.roleData = [];
			this.adminUserForm.roleList = [];
			if (e !== '') {
				this.adminUserForm.organizationId = e;
				this.roleList();
			}
		},
		async roleList() {
			// admin获取非企业的角色
			const dataJson = {
				pageNum: 1,
				pageSize: 10000,
				userName: ''
			};
			const res = await adminRoleListService(dataJson);
			this.roleData = res.records;
		},
		changeRoleHandler(e) {
			if (e.length === this.roleData.length) {
				setTimeout(() => {
					this.$refs.multiSelect.blur();
				}, 50);
			}
		},
		changeHandle(e) {
			if (e.includes(0)) {
				this.adminUserForm.lookArea = [0];
				this.areaData.forEach((item, index) => {
					item.disabled = true;
					if (index === 0) {
						item.disabled = false;
					}
				});
			} else {
				this.areaData.forEach(item => {
					item.disabled = false;
				});
			}
		},
		adminUserFormSubmit() {
			this.$refs.adminUserForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await adminUserAddService(this.adminUserForm);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.adminUserForm
						};
						await adminUserEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
						const dataJson_c = {
							roleList: this.adminUserForm.roleList,
							id: this.editId
						};
						await adminUserRoleService(dataJson_c);
					}
					this.adminUserFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
		},
		adminUserFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
</style>
