import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 设备类型列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   deviceType
 */
export const deviceTypeListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				type: -1,
				...data
			};
			const res = await hpostRequest('hlzlong/deviceType/deviceTypeAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设置重要设备类型
 *@ClassAuthor: myw
 *@Date: 2021-07-28 15:37:22
 */
export const deviceMainService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				paramValue: '',
				...data
			};
			const res = await hpostRequest('hlzlong/parameter/importanceDevice', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备类型添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:00:29
 */
export const deviceTypeAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				deviceType: '',
				...data
			};
			dataJson.pid = dataJson.pid === null ? 0 : dataJson.pid;
			const res = await hpostRequest('hlzlong/deviceType/deviceTypeAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备类型编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:58
 */
export const deviceTypeEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				deviceType: '',
				...data
			};
			dataJson.pid = dataJson.pid === null ? 0 : dataJson.pid;
			const res = await hpostRequest('hlzlong/deviceType/deviceTypeUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备类型详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:59:21
 */
export const deviceTypeDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				deviceTypeId: '',
				...data
			};
			const res = await hgetRequest('hlzlong/deviceType/deviceTypeByIdQuery', dataJson);
			res.pid = res.pid === 0 ? null : Number(res.pid);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备类型删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:03
 */
export const deviceTypeDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest('hlzlong/deviceType/deviceTypeDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default deviceTypeListService;
