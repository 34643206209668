<template>
	<el-row>
		<el-col :span="24">
			<el-form ref="adminMenuForm" :model="adminMenuForm" :rules="adminMenuRules" label-width="88px">
				<el-form-item label="名称" prop="menuName">
					<el-input v-model="adminMenuForm.menuName" placeholder="请输入菜单名称" />
				</el-form-item>
				<el-form-item label="链接地址">
					<el-input v-model="adminMenuForm.url" placeholder="请输入链接地址" />
				</el-form-item>
				<el-form-item label="排序">
					<el-input v-model="adminMenuForm.menuSort" type="number" placeholder="请输入排序" />
				</el-form-item>
				<!-- <el-form-item label="图标">
					<el-input v-model.number="adminMenuForm.menuIcon" placeholder="请输入图标" />
				</el-form-item> -->
				<el-form-item label="路由名称">
					<el-input v-model="adminMenuForm.routeName" placeholder="请输入路由名称" />
				</el-form-item>
				<!-- <el-form-item label="是否显示" prop="isShow">
					<el-select v-model="adminMenuForm.isShow" class="select-item" placeholder="请选择">
						<el-option label="显示" :value="0"></el-option>
						<el-option label="不显示" :value="1"></el-option>
					</el-select>
				</el-form-item> -->
				<!-- <el-form-item label="按钮类型">
					<el-select v-model="adminMenuForm.btnType" class="select-item" placeholder="请选择">
						<el-option label="请选择" :value="2"></el-option>
						<el-option label="弹框" :value="0"></el-option>
						<el-option label="页签" :value="1"></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="状态" prop="state">
					<el-select v-model="adminMenuForm.state" class="select-item" placeholder="请选择">
						<el-option label="正常" :value="0"></el-option>
						<el-option label="禁用" :value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="是否外链" prop="targetProperty">
					<el-select v-model="adminMenuForm.targetProperty" class="select-item" placeholder="请选择">
						<el-option label="是" value="_blank"></el-option>
						<el-option label="否" value="_self"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="父级">
					<el-select v-model="parentName" class="select-item" placeholder="请选择" collapse-tags clearable @clear="clearHandler">
						<el-option :value="adminMenuForm.pid" style="height: auto">
							<el-tree
								:data="adminMenuData"
								node-key="id"
								ref="tree"
								highlight-current
								:props="defaultProps"
								@node-click="nodeClickHandler"
							></el-tree>
						</el-option>
					</el-select>
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer">
				<el-button @click="adminMenuFormCancel">取 消</el-button>
				<el-button type="primary" @click="adminMenuFormSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
import { adminMenuAddService, adminMenuEditService, adminMenuListService } from '@s/admin/AdminMenuService';
import { deleteTreeNodeById, getTreeNodeById } from '@u/htools.tree';

export default {
	props: ['selectData_p'],
	data() {
		return {
			editId: -1,
			adminMenuForm: {
				menuName: '',
				url: '',
				menuSort: '',
				menuIcon: 1,
				isShow: 0,
				state: 0,
				pid: 0,
				routeName: '',
				targetProperty: '_self',
				btnType: 0
			},
			adminMenuRules: {
				menuName: [
					{
						required: true,
						message: '请输入菜单名称',
						trigger: 'blur'
					}
				],
				url: [
					{
						required: true,
						message: '请输入外链',
						trigger: 'blur'
					}
				],
				targetProperty: [
					{
						required: true,
						message: '请输入target属性',
						trigger: 'blur'
					}
				],
				routeName: [
					{
						required: true,
						message: '请输入路由名称',
						trigger: 'blur'
					}
				],
				state: [
					{
						required: true,
						message: '请选择菜单状态',
						trigger: 'change'
					}
				],
				btnType: [
					{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}
				],
				isShow: [
					{
						required: true,
						message: '请选择类型',
						trigger: 'change'
					}
				]
			},
			parentName: '',
			defaultProps: {
				children: 'children',
				label: 'menuName'
			},
			adminMenuData: []
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.adminMenuData.length === 0 && (await this.adminMenuList());
					getTreeNodeById(this.adminMenuData, 'id', newValue[0].pid, node => {
						this.parentName = node.menuName;
					});
					this.adminMenuForm = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.editId === -1 && this.adminMenuList();
	},
	methods: {
		clearHandler() {
			this.adminMenuForm.pid = 0;
			this.parentName = '';
		},
		async adminMenuList() {
			const dataJson = {};
			const res = await adminMenuListService(dataJson);
			if (this.editId === -1) {
				this.adminMenuData = res;
			} else {
				this.adminMenuData = deleteTreeNodeById(res, this.editId);
			}
		},
		adminMenuFormSubmit() {
			this.$refs.adminMenuForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await adminMenuAddService(this.adminMenuForm);
					} else {
						const dataJson = {
							id: this.editId,
							...this.adminMenuForm
						};
						await adminMenuEditService(dataJson);
					}
					this.$emit('update:isRefreshList_p', true);
					this.adminMenuFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		adminMenuFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		},
		nodeClickHandler(data) {
			this.adminMenuForm.pid = data.id;
			this.parentName = data.menuName;
		}
	}
};
</script>

<style>
</style>
