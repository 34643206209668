<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<MeasureUnitSearchform @searchForm="searchFormHandler"></MeasureUnitSearchform>
			<div>
				<!-- 功能按钮 -->
				<Button
					@showDialogAdd="showDialogAddHandler"
					@showDialogEdit="showDialogEditHandler"
					@showDelete="showDeleteHandler"
					:selectData_p="selectData"
					:delTips_p="delTips"
					:authorize_p="'measureUnit'"
					:editDisabled_p="editDisabled"
				></Button>
			</div>
		</div>
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<el-table-column label="操作" align="left" fixed="right" width="300">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'measureUnit', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'measureUnit', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<MeasureUnitAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
				:editId_p="editId"
			></MeasureUnitAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { measureUnitDeleteService, measureUnitListService, measureUnitDetailService } from '@s/base/MeasureUnitService';

import MeasureUnitAddForm from '@f/base/measureUnit/MeasureUnitAdd.form';
import MeasureUnitSearchform from '@f/base/measureUnit/MeasureUnitSearch.form';

import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';

export default {
	mixins: [ListMixin],
	components: {
		Button,
		Table,
		Pagination,
		Dialog,
		MeasureUnitSearchform,
		MeasureUnitAddForm
	},
	data() {
		return {
			// 表格
			tableColumn: [
                {
					label: '序号',
					field: 'id'
				},
				{
					label: '类目名称',
					field: 'name'
				}
			],
			delTips: '',
			isShowConfigDialog: false,
			editDisabled: true
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增类目名称' : '编辑类目名称';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.measureUnitList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.measureUnitList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.measureUnitList();
			}
		}
	},
	mounted() {
		this.measureUnitList();
	},
	methods: {
		async measureUnitList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				name: '',
				...this.searchForm
			};
			const res = await measureUnitListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				id: editId
			};
			const res = await measureUnitDetailService(dataJson);
			this.selectData = [res];
			this.isShowAEDialog = true;
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = ids;
			await measureUnitDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				id: row.id
			};
			const res = await measureUnitDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.measureUnitList();
		}
	}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>
