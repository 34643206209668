<!--
 *@Description: 资产录入
 *@ClassAuthor: Happy ZXH
 *@Date: 2021-05-19 13:35:01
-->
<template>
	<div class="app-container">
		<el-tabs v-model="activeName" @tab-click="handleClick">
			<el-tab-pane label="基础信息" name="first">
				<el-form
					:model="ruleFormBase"
					:rules="rulesBase"
					ref="ruleFormBase"
					label-width="140px"
					class="ruleFormBase"
				>
					<el-form-item label="设备名称" prop="aaa">
						<el-input v-model="ruleFormBase.aaa"></el-input>
					</el-form-item>
					<el-form-item label="院内编号" prop="aaa">
						<el-input v-model="ruleFormBase.aaa"></el-input>
					</el-form-item>
					<el-form-item label="生产厂家" prop="aaa">
						<el-input v-model="ruleFormBase.aaa"></el-input>
					</el-form-item>
					<el-form-item label="设备型号" prop="aaa">
						<el-input v-model="ruleFormBase.aaa"></el-input>
					</el-form-item>
					<el-form-item label="注册证号" prop="aaa">
						<el-input v-model="ruleFormBase.aaa"></el-input>
					</el-form-item>
					<el-form-item label="设备品牌" prop="aaa">
						<el-input v-model="ruleFormBase.aaa"></el-input>
					</el-form-item>
					<el-form-item label="出厂序列号" prop="aaa">
						<el-input v-model="ruleFormBase.aaa"></el-input>
					</el-form-item>
					<el-form-item label="使用科室" prop="aaa">
						<el-input v-model="ruleFormBase.aaa"></el-input>
					</el-form-item>
					<el-form-item label="使用房间" prop="aaa">
						<el-input v-model="ruleFormBase.aaa"></el-input>
					</el-form-item>
					<el-form-item label></el-form-item>
					<el-form-item label></el-form-item>
					<el-form-item label></el-form-item>
					<el-form-item label="新国际一级" prop="bbb">
						<el-select v-model="ruleFormBase.bbb" placeholder="请选择下拉">
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="新国际二级" prop="bbb">
						<el-select v-model="ruleFormBase.bbb" placeholder="请选择下拉">
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="新国际三级" prop="bbb">
						<el-select v-model="ruleFormBase.bbb" placeholder="请选择下拉">
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="68分类(大类)" prop="bbb">
						<el-select v-model="ruleFormBase.bbb" placeholder="请选择下拉">
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="68分类(小类)" prop="bbb">
						<el-select v-model="ruleFormBase.bbb" placeholder="请选择下拉">
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="性能检测模板" prop="bbb">
						<el-select v-model="ruleFormBase.bbb" placeholder="请选择下拉">
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="设备分类(大类)" prop="bbb">
						<el-select v-model="ruleFormBase.bbb" placeholder="请选择下拉">
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="设备分类(小类)" prop="bbb">
						<el-select v-model="ruleFormBase.bbb" placeholder="请选择下拉">
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label></el-form-item>
					<el-form-item label></el-form-item>
					<el-form-item label></el-form-item>
					<el-form-item label></el-form-item>
					<el-form-item label="备注" prop="aaa">
						<el-input v-model="ruleFormBase.aaa"></el-input>
					</el-form-item>
					<el-form-item label="财务编码" prop="aaa">
						<el-input v-model="ruleFormBase.aaa"></el-input>
					</el-form-item>
					<el-form-item label="档案编号" prop="aaa">
						<el-input v-model="ruleFormBase.aaa"></el-input>
					</el-form-item>

					<el-form-item>
						<el-button type="primary" @click="submitFormBase('ruleFormBase')">立即创建</el-button>
						<el-button @click="resetFormBase('ruleFormBase')">重置</el-button>
					</el-form-item>
				</el-form>
			</el-tab-pane>
			<el-tab-pane label="属性信息" name="second">
				<el-form
					:model="ruleFormAttribute"
					:rules="rulesAttribute"
					ref="ruleFormAttribute"
					label-width="140px"
					class="ruleFormAttribute"
				>
					<el-form-item label="是否防护设备" prop="bbb">
						<el-select v-model="ruleFormAttribute.bbb" placeholder="请选择下拉" filterable clearable>
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否特种设备" prop="bbb">
						<el-select v-model="ruleFormAttribute.bbb" placeholder="请选择下拉" filterable clearable>
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否计量设备" prop="bbb">
						<el-select v-model="ruleFormAttribute.bbb" placeholder="请选择下拉" filterable clearable>
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="是否租聘设备" prop="bbb">
						<el-select v-model="ruleFormAttribute.bbb" placeholder="请选择下拉" filterable clearable>
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="设备批次" prop="bbb">
						<el-select v-model="ruleFormAttribute.bbb" placeholder="请选择下拉" filterable clearable>
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="大型医院设备" prop="bbb">
						<el-select v-model="ruleFormAttribute.bbb" placeholder="请选择下拉" filterable clearable>
							<el-option label="下拉1" value="1"></el-option>
							<el-option label="下拉2" value="2"></el-option>
						</el-select>
					</el-form-item>
                    
					<el-form-item>
						<el-button type="primary" @click="submitFormAttribute('ruleFormAttribute')">立即创建</el-button>
						<el-button @click="resetFormAttribute('ruleFormAttribute')">重置</el-button>
					</el-form-item>
				</el-form>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
export default {
	name: '',
	data() {
		return {
			activeName: 'first',
			// 基础信息表单 S =======================================
			ruleFormBase: {
				name: '',
				aaa: '',
				bbb: ''
			},
			rulesBase: {
				aaa: [{ required: true, message: '请输入活动名称', trigger: 'blur' }]
			},

			// 基础信息表单 E =======================================
			// 属性信息表单 S ***************************************
			ruleFormAttribute: {
				name: '',
				aaa: '',
				bbb: ''
			},
			rulesAttribute: {
				aaa: [{ required: true, message: '请输入活动名称', trigger: 'blur' }]
			}
			// 属性信息表单 E ***************************************
		};
	},

	components: {},

	watch: {},

	computed: {},

	mounted() {},

	methods: {
		handleClick(tab, event) {
			console.log(tab, event);
		},
		// 基础信息表单 S =======================================
		submitFormBase(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					console.log('submit!');
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		resetFormBase(formName) {
			this.$refs[formName].resetFields();
		},
		// 基础信息表单 E =======================================
		// 属性信息表单 S ***************************************
		submitFormAttribute(formName) {
			this.$refs[formName].validate(valid => {
				if (valid) {
					console.log('submit!');
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		},
		resetFormAttribute(formName) {
			this.$refs[formName].resetFields();
		}
		// 属性信息表单 E ***************************************
	},

	destroyed() {}
};
</script>
<style lang='less' scoped>
/deep/ .ruleFormBase,/deep/.ruleFormAttribute {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
}
/deep/.ruleFormBase .el-form-item ,/deep/.ruleFormAttribute .el-form-item {
	width: 30%;
}
</style>
