import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 系统参数列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   systemParams
 */
export const systemParamsListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				paramKey: '',
				paramValue: '',
				...data
			};
			const res = await hpostRequest('hlzlong/parameter/parameterAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 系统参数添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:00:29
 */
export const systemParamsAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				paramKey: '',
				paramValue: '',
				...data
			};
			const res = await hpostRequest('hlzlong/parameter/parameterAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 系统参数编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:58
 */
export const systemParamsEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
				paramKey: '',
				paramValue: '',
				...data
			};
			const res = await hpostRequest('hlzlong/parameter/parameterUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 系统参数详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:59:21
 */
export const systemParamsDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				parameterId: '',
				...data
			};
			const res = await hgetRequest('hlzlong/parameter/parameterByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 系统参数删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:03
 */
export const systemParamsDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest('hlzlong/parameter/parameterDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default systemParamsListService;
