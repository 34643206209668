<template>
	<div class="app-container">
		<el-row>
			<el-col :span="4">
				<el-tree :data="deviceTypeData" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
			</el-col>
			<el-col :span="20">
				<div class="head-container">
					<!-- 搜索区域 -->
					<NormSearchform @searchForm="searchFormHandler"></NormSearchform>
					<div>
						<!-- 功能按钮 -->
						<Button
							@showDialogAdd="showDialogAddHandler"
							@showDialogEdit="showDialogEditHandler"
							@showDelete="showDeleteHandler"
							:selectData_p="selectData"
							:delTips_p="delTips"
							:authorize_p="'norm'"
							:editDisabled_p="editDisabled"
						></Button>
					</div>
				</div>
				<!--表格渲染-->
				<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
					<el-table-column label="图片">
						<template slot-scope="scope">
							<el-image
								class="devBaseFormLogoStr"
								style="width: 30px; height: 30px"
								:src="picture + scope.row.picture"
								:preview-src-list="[picture + scope.row.picture]"
							></el-image>
						</template>
					</el-table-column>
					<el-table-column label="创建时间">
						<template slot-scope="scope">{{ scope.row.createDate | formatDate }}</template>
					</el-table-column>
					<el-table-column label="操作" align="left" fixed="right" width="300">
						<template slot-scope="scope">
							<el-button
								id="btn-update-row"
								type="primary"
								v-authorize="{ name: 'update', type: 'norm', id: 'btn-update-row' }"
								@click="editSingleHandler(scope.row)"
							>
								编辑
							</el-button>
							<el-button
								id="btn-remove-row"
								type="danger"
								v-authorize="{ name: 'remove', type: 'norm', id: 'btn-remove-row' }"
								@click="deleteSingleHandler(scope.row)"
							>
								删除
							</el-button>
						</template>
					</el-table-column>
				</Table>
				<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
				<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
					<NormAddForm
						v-if="isShowAEDialog"
						:isShowAEDialog_p.sync="isShowAEDialog"
						:isRefreshList_p.sync="isRefreshList"
						:selectData_p="selectData"
						:editId_p="editId"
						:deviceTypeId_p="deviceTypeId"
					></NormAddForm>
				</Dialog>
			</el-col>
		</el-row>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { normDeleteService, normListService, normDetailService } from '@s/base/NormService';
// eslint-disable-next-line import/no-cycle
import { deviceTypeListService } from '@s/base/DeviceTypeService';

import NormAddForm from '@f/base/norm/NormAdd.form';
import NormSearchform from '@f/base/norm/NormSearch.form';

import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';
import { changeTreeDataToChildren } from '@u/htools.tree';

export default {
	mixins: [ListMixin],
	components: {
		NormSearchform,
		Button,
		Table,
		Pagination,
		Dialog,
		NormAddForm
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '标准名称',
					field: 'deviceName'
				},
				{
					label: '标准型号',
					field: 'deviceModel'
				},
				{
					label: '设备分类',
					field: 'deviceType'
				}
			],
			delTips: '',
			isShowConfigDialog: false,
			editDisabled: true,
			picture: '',
			deviceTypeData: null,
			deviceTypeId: null,
			defaultProps: {
				children: 'children',
				label: 'deviceType'
			}
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增标准型号' : '编辑标准型号';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.normList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.normList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.normList();
			}
		}
	},
	mounted() {
		this.normList();
		this.deviceTypeList();
		setTimeout(() => {
			this.picture = `${this.$envConfig.imgHttp}${this.$envConfig.imgFile.norm}/`;
		}, 1000);
	},
	methods: {
		async deviceTypeList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000,
				type: -1
			};
			const res = await deviceTypeListService(dataJson);
			this.deviceTypeData = changeTreeDataToChildren([...res.records]);
		},
		async normList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				name: '',
				deviceTypeId: this.deviceTypeId,
				...this.searchForm
			};
			const res = await normListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				deviceNameId: editId
			};
			const res = await normDetailService(dataJson);
			this.selectData = [res];
			this.isShowAEDialog = true;
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await normDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			console.log(row);
			const dataJson = {
				deviceNameId: row.id
			};
			const res = await normDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.normList();
		},
		handleNodeClick(data) {
			if (data.pid !== 0) {
				this.deviceTypeId = data.id;
				this.normList();
			} else {
				this.deviceTypeId = null;
				return false;
			}
		}
	}
};
</script>

<style lang="less" scoped>
/deep/.el-image-viewer__close {
	top: 80px !important;
	color: #ffffff;
}
/deep/.el-image__error {
	line-height: 14px;
}
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.app-container /deep/.el-tree-node:focus > .el-tree-node__content {
	background: transparent;
}
.app-container /deep/.el-tree-node__content:hover {
	background: #409eff;
	color: #fff;
}
.app-container /deep/.is-current {
	background: #409eff;
	color: #fff;
}
</style>
