import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 标准名称列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   norm
 */
export const normListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				deviceName: '',
				...data
			};
			const res = await hpostRequest('hlzlong/deviceName/deviceNameAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 标准名称添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:00:29
 */
export const normAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				deviceManufacturer: '',
				deviceModel: '',
				deviceName: '',
				deviceTypeId: '',
				...data
			};
			const res = await hpostRequest('hlzlong/deviceName/deviceNameAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 标准名称编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:58
 */
export const normEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
				...data
			};
			const res = await hpostRequest('hlzlong/deviceName/deviceNameUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 标准名称详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:59:21
 */
export const normDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				deviceNameId: '',
				...data
			};
			const res = await hgetRequest('hlzlong/deviceName/deviceNameByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 标准名称删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:03
 */
export const normDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest('hlzlong/deviceName/deviceNameDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default normListService;
