import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 出入库类型列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   issueReceiptType
 */
export const issueReceiptTypeListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/inouttype/all', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 包装单位添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:00:29
 */
export const measureUnitAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				...data
			};
			const res = await hpostRequest('hwms/units/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 出入库类型编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:58
 */
export const issueReceiptTypeEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
                inOutType: 0,
                enable: true,
				...data
			};
			const res = await hpostRequest('hwms/inouttype/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 出入库类型详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:59:21
 */
export const issueReceiptTypeDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hwms/inouttype/getById', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 包装单位删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:03
 */
export const measureUnitDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: [],
				...data
			};
			const res = await hpostRequest('hwms/units/delete', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default issueReceiptTypeListService;
