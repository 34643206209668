<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<BSclassSearchform @searchForm="searchFormHandler"></BSclassSearchform>
			<div>
				<!-- 功能按钮 -->
				<Button
					@showDialogAdd="showDialogAddHandler"
					@showDialogEdit="showDialogEditHandler"
					@showDelete="showDeleteHandler"
					:selectData_p="selectData"
					:delTips_p="delTips"
					:authorize_p="'bsclass'"
					:editDisabled_p="editDisabled"
				></Button>
			</div>
		</div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
			row-key="id"
			:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
		>
			<el-table-column label="创建时间">
				<template slot-scope="scope">
					{{ scope.row.createDate | formatDate }}
				</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right" width="300">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'bsclass', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'bsclass', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<BSclassAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
				:editId_p="editId"
			></BSclassAddForm>
		</Dialog>
	</div>
</template>

<script>
import { changeTreeDataToChildren } from '@u/htools.tree';

// eslint-disable-next-line import/no-cycle
import { bsclassDeleteService, bsclassListService, bsclassDetailService } from '@s/base/BSclassService';

import BSclassAddForm from '@f/base/bsclass/BSclassAdd.form';
import BSclassSearchform from '@f/base/bsclass/BSclassSearch.form';

import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';

export default {
	mixins: [ListMixin],
	components: {
		BSclassSearchform,
		Button,
		Table,
		Pagination,
		Dialog,
		BSclassAddForm
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '名称',
					field: 'name'
				}
			],
			delTips: '',
			isShowConfigDialog: false,
			editDisabled: true,
			picture: ''
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增' : '编辑';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.bsclassList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.bsclassList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.bsclassList();
			}
		}
	},
	mounted() {
		this.bsclassList();
	},
	methods: {
		async bsclassList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				name: '',
				...this.searchForm
			};
			const res = await bsclassListService(dataJson);
			res.records = changeTreeDataToChildren(res.records);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				sizeClassesId: editId
			};
			const res = await bsclassDetailService(dataJson);
			this.selectData = [res];
			this.isShowAEDialog = true;
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await bsclassDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				sizeClassesId: row.id
			};
			const res = await bsclassDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.bsclassList();
		}
	}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>
