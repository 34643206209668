<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<HospitalLevelSearchform @searchForm="searchFormHandler"></HospitalLevelSearchform>
			<div>
				<!-- 功能按钮 -->
				<Button
					@showDialogAdd="showDialogAddHandler"
					@showDialogEdit="showDialogEditHandler"
					@showDelete="showDeleteHandler"
					:selectData_p="selectData"
					:delTips_p="delTips"
					:authorize_p="'hospitalLevel'"
					:editDisabled_p="editDisabled"
				></Button>
			</div>
		</div>
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<el-table-column label="创建时间">
				<template slot-scope="scope">
					{{ scope.row.createDate | formatDate }}
				</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right" width="300">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'hospitalLevel', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'hospitalLevel', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<HospitalLevelAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
				:editId_p="editId"
			></HospitalLevelAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { hospitalLevelDeleteService, hospitalLevelListService, hospitalLevelDetailService } from '@s/base/HospitalLevelService';

import HospitalLevelAddForm from '@f/base/hospitalLevel/HospitalLevelAdd.form';
import HospitalLevelSearchform from '@f/base/hospitalLevel/HospitalLevelSearch.form';

import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';

export default {
	mixins: [ListMixin],
	components: {
		HospitalLevelSearchform,
		Button,
		Table,
		Pagination,
		Dialog,
		HospitalLevelAddForm
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '医院等级',
					field: 'grade'
				}
			],
			delTips: '',
			isShowConfigDialog: false,
			editDisabled: true
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增医院等级' : '编辑医院等级';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.hospitalLevelList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.hospitalLevelList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.hospitalLevelList();
			}
		}
	},
	mounted() {
		this.hospitalLevelList();
	},
	methods: {
		async hospitalLevelList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				grade: '',
				...this.searchForm
			};
			const res = await hospitalLevelListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				hospitalGradeId: editId
			};
			const res = await hospitalLevelDetailService(dataJson);
			this.selectData = [res];
			this.isShowAEDialog = true;
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await hospitalLevelDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				hospitalGradeId: row.id
			};
			const res = await hospitalLevelDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.hospitalLevelList();
		}
	}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>
