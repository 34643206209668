<template>
	<el-row>
		<el-col :span="12">
			<i class="el-icon-s-fold collapse-btn" @click="collapseHandler" style="font-size: 20px"></i>
		</el-col>
		<el-col :span="12" style="text-align: right">
			<el-dropdown>
				<i
					class="el-icon-setting"
					style="margin-right: 15px; font-size: 20px; color: #bfcbd9;cursor:pointer;"
				></i>
				<el-dropdown-menu slot="dropdown">
					<el-dropdown-item @click.native="dialogVisibleChangePassword">修改密码</el-dropdown-item>
					<el-dropdown-item @click.native="loginOut">退出</el-dropdown-item>
				</el-dropdown-menu>
			</el-dropdown>
			<span style="font-size: 16px">{{ userName }}</span>
		</el-col>
		<el-col>
			<Dialog title="修改密码" :visible.sync="dialogVisible" width="30%">
				<el-form :model="changePwd" :rules="rulesChangePwd" ref="changePwd" label-width="150px">
					<el-form-item label="原密码" prop="userPwdOld">
						<el-input v-model="changePwd.userPwdOld" autocomplete="off" type="password"></el-input>
					</el-form-item>
					<el-form-item label="新密码" prop="userPwdNew">
						<el-input v-model="changePwd.userPwdNew" autocomplete="off" type="password"></el-input>
					</el-form-item>
					<el-form-item label="确认密码" prop="checkPass">
						<el-input v-model="changePwd.checkPass" autocomplete="off" type="password"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="submitForm('changePwd')">提交</el-button>
						<el-button @click="resetForm('changePwd')">重置</el-button>
					</el-form-item>
				</el-form>
			</Dialog>
		</el-col>
	</el-row>
</template>

<script>
import md5 from 'js-md5';
// eslint-disable-next-line import/named
import { adminUserLoginDetailService, adminUserPasswordEditService1 } from '@s/admin/AdminUserService.js';
import { hsetStorage } from '@u/htools.web.js';
import Dialog from '@c/ui/Dialog.vue';

export default {
	props: ['isCollapse_p'],
	components: {
		Dialog
	},
	data() {
		const validatePass3 = (rule, value, callback) => {
			if (md5(value) !== this.userPwd.replace('{MD5}', '')) {
				callback(new Error('原密码错误，请重新输入！'));
			} else {
				callback();
			}
			// else if (value.length < 6 || value > 16) {
			// 	callback(new Error('密码长度不符！'));
			// }
		};
		const validatePass = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请输入密码'));
			} else {
				if (this.changePwd.checkPass !== '') {
					this.$refs.changePwd.validateField('checkPass');
				}
				callback();
			}
		};
		const validatePass2 = (rule, value, callback) => {
			if (value === '') {
				callback(new Error('请再次输入密码'));
			} else if (value !== this.changePwd.userPwdNew) {
				callback(new Error('两次输入密码不一致!'));
			} else {
				callback();
			}
		};
		return {
			userName: '',
			userPwd: '123456',
			dialogVisible: false,
			changePwd: {
				userPwdOld: null, // 旧密码
				userPwdNew: null, // 新密码
				checkPass: null // 确认密码
			},
			rulesChangePwd: {
				userPwdOld: [
					{ required: true, message: '请输入原密码！', trigger: 'blur' },
					{ required: true, validator: validatePass3, trigger: 'blur' },
					{ min: 6, max: 16, message: '长度在6到16个字符', trigger: 'blur' }
				],
				userPwdNew: [
					{ required: true, validator: validatePass, trigger: 'blur' },
					{ min: 6, max: 16, message: '长度在6到16个字符', trigger: 'blur' }
				],
				checkPass: [{ required: true, validator: validatePass2, trigger: 'blur' }]
			}
		};
	},
	created() {
		this.userDetail();
	},
	methods: {
		collapseHandler() {
			this.$emit('update:isCollapse_p', !this.isCollapse_p);
		},
		async userDetail() {
			const dataJson = {};
			const res = await adminUserLoginDetailService(dataJson);
			this.userName = res.userName;
			this.userPwd = res.userPwd;
			// hsetStorage('organization', res.organization === null ? 0 : res.organization.id);
			hsetStorage('powers', res.powers);
			hsetStorage('roleIds', res.userRoles);
		},
		dialogVisibleChangePassword() {
			// 修改密码 弹框
			this.dialogVisible = true;
			// setTimeout(() => {
			this.$refs.changePwd && this.$refs.changePwd.resetFields();
			// }, 100);
		},

		submitForm(formName) {
			this.$refs[formName].validate(async valid => {
				if (!valid) {
					return false;
				}
				const res = await this.$confirm('确认修改', {
					type: 'warning'
				});

				if (res !== 'confirm') {
					return false;
				}
				delete this.changePwd.checkPass;
				const res1 = await adminUserPasswordEditService1(this.changePwd);
				if (res1.code !== 200) {
					res1.msg && this.$message.warning(res1.msg);
				}
				this.$message.success('修改密码成功！');
				this.dialogVisible = true;
				this.loginOut();
				// userPasswordEditService
			});
		},
		resetForm(formName) {
			this.$refs[formName].resetFields();
		},
		loginOut() {
			this.$store.dispatch('loginOutStore');
			this.$router.replace('/login');
		}
	}
};
</script>

<style scoped lang="less">
.collapse-btn {
	cursor: pointer;
}
.el-dropdown {
	height: 34px;
}
</style>
