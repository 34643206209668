<template>
	<el-row>
		<el-col :span="24">
			<el-form ref="userForm" :model="userForm" :rules="userRules" size="small" label-width="100px">
				<el-form-item label="用户名" prop="userName">
					<el-input v-model="userForm.userName" placeholder="请输入用户名" />
				</el-form-item>
				<el-form-item label="密码" prop="userPwd" v-if="editId === -1">
					<el-input type="password" v-model="userForm.userPwd" placeholder="请输入密码" />
				</el-form-item>
				<el-form-item label="昵称" prop="nickName">
					<el-input v-model="userForm.nickName" placeholder="请输入昵称" />
				</el-form-item>
				<el-form-item label="手机号" prop="telphone">
					<el-input v-model="userForm.telphone" placeholder="请输入手机号" />
				</el-form-item>
				<template v-if="userForm.roleList !== null">
					<!-- <el-form-item label="角色" v-if="userForm.roleList.indexOf($envConfig.factoryAdminRoleId) !== -1">
						<el-input value="工厂管理员" readonly="" />
					</el-form-item>
					<el-form-item label="角色" v-else-if="userForm.roleList.indexOf($envConfig.superAdminRoleId) !== -1">
						<el-input value="管理员" readonly="" />
					</el-form-item> -->
					<!-- v-if="userForm.roleList.indexOf($envConfig.factoryAdminRoleId) === -1 && 
					userForm.roleList.indexOf($envConfig.superAdminRoleId) === -1" -->
					<el-form-item label="角色" prop="roleList">
						<el-select ref="multiSelect" v-model="userForm.roleList" multiple filterable placeholder="请选择角色" class="select-item" clearable>
							<el-option v-for="(role, index) in roleData" :key="index" :label="role.roleName" :value="role.id"></el-option>
						</el-select>
					</el-form-item>
				</template>
				<el-form-item label="状态" prop="state">
					<el-radio-group style="width: 200px" v-model="userForm.state">
						<el-radio :label="0">启用</el-radio>
						<el-radio :label="1">不启用</el-radio>
					</el-radio-group>
				</el-form-item>

				<el-form-item label="备注">
					<el-input type="textarea" :rows="4" v-model="userForm.remark"></el-input>
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer" style="text-align: right">
				<el-button @click="userFormCancel">取 消</el-button>
				<el-button type="primary" @click="userFormSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
// eslint-disable-next-line import/named
import { userAddService, userEditService, userRoleService } from '@s/system/UserService';
// eslint-disable-next-line import/no-cycle
import { roleListService, roleAdminListService } from '@s/system/RoleService';
import { arrayIncludeItem } from '@u/index';

export default {
	props: ['selectData_p', 'editId_p'],
	data() {
		const phoneValid = (rule, value, callback) => {
			const reg = /^(?:(?:\+|00)86)?1\d{10}$/;
			if (value && !reg.test(value)) {
				callback(new Error('请输入正确的手机号码'));
			} else {
				callback();
			}
		};
		return {
			type: 0,
			editId: -1,
			// 弹窗
			userForm: {
				userName: '',
				userPwd: '',
				nickName: '',
				state: 0,
				organizationId: '',
				telphone: '',
				remark: '',
				roleList: []
			},
			areaData: [
				{
					id: 0,
					name: '无权限查看'
				},
				{
					id: 1,
					name: '贵溪市'
				},
				{
					id: 2,
					name: '余江区'
				},
				{
					id: 3,
					name: '高新区'
				}
			],
			userRules: {
				userName: [
					{ required: true, message: '请输入用户名', trigger: 'blur' },
					{ min: 2, max: 20, message: '长度在 2 到 20 个字符', trigger: 'blur' }
				],
				userPwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
				nickName: [{ required: true, message: '请输入用户昵称', trigger: 'blur' }],
				state: [{ required: true, message: '请选择状态', trigger: 'change' }],
				roleList: [
					{
						required: true,
						message: '请选择角色',
						trigger: 'change'
					}
				],
				organizationId: [
					{
						required: true,
						message: '请选择企业',
						trigger: 'change'
					}
				],
				telphone: [
					{
						required: true,
						message: '请输入手机号',
						trigger: 'blur'
					},
					{
						validator: phoneValid,
						trigger: 'blur'
					}
				]
			},
			roleData: []
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.roleData.length === 0 && (await this.roleList());
					this.editId = newValue[0].id;
					this.userForm = { ...newValue[0] };
					this.$set(this.userForm, 'roleList', newValue[0].userRoles);
					newValue[0].userRoles.forEach(item => {
						const result = arrayIncludeItem(this.roleData, item);
						if (!result) {
							const index = newValue[0].userRoles.indexOf(item);
							newValue[0].userRoles.splice(index, 1);
							this.$set(this.userForm, 'roleList', newValue[0].userRoles);
						}
					});
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.editId = this.editId_p;
		if (this.editId === -1) {
			this.roleList();
		}
	},
	methods: {
		// 选择企业重新获取角色
		async changeOrgHandler(e) {
			this.roleData = [];
			this.userForm.roleList = [];
			if (e !== '') {
				this.userForm.organizationId = e;
				this.roleList();
			}
		},
		async roleList() {
			let dataJson;
			let res;
			if (this.userForm.organizationId !== 0) {
				dataJson = {
					pageNum: 1,
					pageSize: 10000,
					userName: '',
					organizationId: this.userForm.organizationId
				};
				// 根据企业id获取角色
				res = await roleListService(dataJson);
				res.records.push({
					id: 30,
					roleName: '工厂管理员'
				});
				this.roleData = res.records;
			} else {
				// admin获取非企业的角色
				dataJson = {
					pageNum: 1,
					pageSize: 10000,
					userName: ''
				};
				res = await roleAdminListService(dataJson);
				this.roleData = res;
			}
		},
		changeRoleHandler(e) {
			if (e.length === this.roleData.length) {
				setTimeout(() => {
					this.$refs.multiSelect.blur();
				}, 50);
			}
		},
		changeHandle(e) {
			this.userForm.organizationId = '';
			if (e === 0) {
				this.hospitalList();
			}
			if (e === 1) {
				this.companyList();
			}
		},
		userFormSubmit() {
			this.$refs.userForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await userAddService(this.userForm);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.userForm
						};
						await userEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
						const dataJson_c = {
							roleList: this.userForm.roleList,
							id: this.editId
						};
						await userRoleService(dataJson_c);
					}
					this.userFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
		},
		userFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style lang="less" scoped>
</style>
