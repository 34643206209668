import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 计量单位列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const unitListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				...data
			};
			const res = await hpostRequest('hlzlong/units/unitsAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 计量单位列表所有
 *@ClassAuthor: myw
 *@Date: 2021-05-20 16:38:05
 */
 export const unitListAllService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hlzlong/hospital/hospitalSelectAll', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 计量单位添加
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:34
 */
export const unitAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				...data
			};
			const res = await hpostRequest('hlzlong/units/unitsAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 计量单位修改
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:53
 */
export const unitEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
				...data
			};
			const res = await hpostRequest('hlzlong/units/unitsUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 计量单位详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const unitDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				unitsId: '',
				...data
			};
			const res = await hgetRequest('hlzlong/units/unitsByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院删除
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:33
 */
export const unitDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest('hlzlong/units/unitsDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default unitListService;
