<template>
	<div class="app-container">
		<el-row>
			<el-col :span="4">
				<el-tree :data="deviceTypeData" :props="defaultProps" @node-click="handleNodeClick"></el-tree>
			</el-col>
			<el-col :span="20">
				<div class="head-container">
					<!-- 搜索区域 -->
					<FaultSearchform @searchForm="searchFormHandler"></FaultSearchform>
					<div>
						<!-- 功能按钮 -->
						<Button
							@showDialogAdd="showDialogAddHandler"
							@showDialogEdit="showDialogEditHandler"
							@showDelete="showDeleteHandler"
							:selectData_p="selectData"
							:delTips_p="delTips"
							:authorize_p="'fault'"
							:editDisabled_p="editDisabled"
						></Button>
					</div>
				</div>
				<!--表格渲染-->
				<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
					<el-table-column label="创建时间">
						<template slot-scope="scope">
							{{ scope.row.createDate | formatDate }}
						</template>
					</el-table-column>
					<el-table-column label="操作" align="left" fixed="right" width="300">
						<template slot-scope="scope">
							<el-button
								id="btn-update-row"
								type="primary"
								v-authorize="{ name: 'update', type: 'fault', id: 'btn-update-row' }"
								@click="editSingleHandler(scope.row)"
							>
								编辑
							</el-button>
							<el-button
								id="btn-remove-row"
								type="danger"
								v-authorize="{ name: 'remove', type: 'fault', id: 'btn-remove-row' }"
								@click="deleteSingleHandler(scope.row)"
							>
								删除
							</el-button>
						</template>
					</el-table-column>
				</Table>
				<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
				<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
					<FaultAddForm
						v-if="isShowAEDialog"
						:isShowAEDialog_p.sync="isShowAEDialog"
						:isRefreshList_p.sync="isRefreshList"
						:selectData_p="selectData"
						:editId_p="editId"
						:deviceTypeId_p="deviceTypeId"
					></FaultAddForm>
				</Dialog>
			</el-col>
		</el-row>
	</div>
</template>

<script>
import { changeTreeDataToChildren } from '@u/htools.tree';
// eslint-disable-next-line import/no-cycle
import { faultDeleteService, faultListService, faultDetailService } from '@s/base/FaultService';
// eslint-disable-next-line import/no-cycle
import { deviceTypeListService } from '@s/base/DeviceTypeService';

import FaultAddForm from '@f/base/fault/FaultAdd.form';
import FaultSearchform from '@f/base/fault/FaultSearch.form';

import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';

export default {
	mixins: [ListMixin],
	components: {
		FaultSearchform,
		Button,
		Table,
		Pagination,
		Dialog,
		FaultAddForm
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '故障名称',
					field: 'name'
				},
				{
					label: '设备类型',
					field: 'deviceName'
				}
			],
			delTips: '',
			isShowConfigDialog: false,
			deviceTypeData: null,
			deviceTypeId: null,
			editDisabled: true,
			defaultProps: {
				children: 'children',
				label: 'deviceType'
			}
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增故障' : '编辑故障';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.faultList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.faultList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.faultList();
			}
		}
	},
	mounted() {
		this.deviceTypeList();
		this.faultList();
	},
	methods: {
		async deviceTypeList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000,
				type: -1
			};
			const res = await deviceTypeListService(dataJson);
			this.deviceTypeData = changeTreeDataToChildren([...res.records]);
		},
		async faultList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				name: '',
				id: this.deviceTypeId,
				...this.searchForm
			};
			const res = await faultListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				problemId: editId
			};
			const res = await faultDetailService(dataJson);
			this.selectData = [res];
			this.isShowAEDialog = true;
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await faultDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				problemId: row.id
			};
			const res = await faultDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.faultList();
		},
		handleNodeClick(data) {
			if (data.pid !== 0) {
				this.deviceTypeId = data.id;
				this.faultList();
			} else {
				this.deviceTypeId = null;
				return false;
			}
		}
	}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
.app-container /deep/.el-tree-node:focus > .el-tree-node__content {
	background: transparent;
}
.app-container /deep/.el-tree-node__content:hover {
	background: #409eff;
	color: #fff;
}
.app-container /deep/.is-current {
	background: #409eff;
	color: #fff;
}
</style>
