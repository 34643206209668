<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<DeviceTypeSearchform @searchForm="searchFormHandler"></DeviceTypeSearchform>
			<div>
				<!-- 功能按钮 -->
				<Button
					@showDialogAdd="showDialogAddHandler"
					@showDialogEdit="showDialogEditHandler"
					@showDelete="showDeleteHandler"
					:selectData_p="selectData"
					:delTips_p="delTips"
					:authorize_p="'deviceType'"
					:editDisabled_p="editDisabled"
				>
					<el-button type="success" :disabled="selectData.length === 0" @click="setMainDevice">设置重要设备</el-button>
				</Button>
			</div>
		</div>
		<!--表格渲染-->
		<Table
			ref="tableDom"
			:data.sync="tableData"
			:tableColumn_p="tableColumn"
			:selectData_p.sync="selectData"
			row-key="id"
			:tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
		>
			<el-table-column label="创建时间">
				<template slot-scope="scope">
					{{ scope.row.createDate | formatDate }}
				</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right" width="300">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'deviceType', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'deviceType', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<DeviceTypeAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:selectData_p="selectData"
				:editId_p="editId"
			></DeviceTypeAddForm>
		</Dialog>
	</div>
</template>

<script>
import { changeTreeDataToChildren } from '@u/htools.tree';

// eslint-disable-next-line import/no-cycle
import { deviceTypeDeleteService, deviceTypeListService, deviceTypeDetailService, deviceMainService } from '@s/base/DeviceTypeService';

import DeviceTypeAddForm from '@f/base/deviceType/DeviceTypeAdd.form';
import DeviceTypeSearchform from '@f/base/deviceType/DeviceTypeSearch.form';

import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import ListMixin from '@m/List.mixin';

export default {
	mixins: [ListMixin],
	components: {
		DeviceTypeSearchform,
		Button,
		Table,
		Pagination,
		Dialog,
		DeviceTypeAddForm
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '设备类型名称',
					field: 'deviceType'
				}
			],
			delTips: '',
			isShowConfigDialog: false,
			editDisabled: true
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增设备类型' : '编辑设备类型';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.deviceTypeList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.deviceTypeList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.deviceTypeList();
			}
		}
	},
	mounted() {
		this.deviceTypeList();
	},
	methods: {
		async setMainDevice() {
			const ids = this.selectData.map(item => {
				return item.id;
			});
			const dataJson = {
				paramValue: ids.join(',')
			};
			const res = await deviceMainService(dataJson);
			console.log(res);
		},
		async deviceTypeList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				name: '',
				type: -1,
				...this.searchForm
			};
			const res = await deviceTypeListService(dataJson);
			res.records = changeTreeDataToChildren(res.records);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				deviceTypeId: editId
			};
			const res = await deviceTypeDetailService(dataJson);
			this.selectData = [res];
			this.isShowAEDialog = true;
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await deviceTypeDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				deviceTypeId: row.id
			};
			const res = await deviceTypeDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.deviceTypeList();
		}
	}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>
