<template>
	<div class="app-container">
		<div class="head-container">
			<!-- 搜索区域 -->
			<UserSearchform @searchForm="searchFormHandler"></UserSearchform>
			<div>
				<!-- 功能按钮 -->
				<Button
					@showDialogAdd="showDialogAddHandler"
					@showDialogEdit="showDialogEditHandler"
					@showDelete="showDeleteHandler"
					:selectData_p="selectData"
					:delTips_p="delTips"
					:authorize_p="'userUser'"
					:editDisabled_p="editDisabled"
				></Button>
			</div>
		</div>
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<el-table-column label="角色">
				<template slot-scope="scope">
					{{ scope.row.roleList == null ? '' : scope.row.roleNamesList.toString().replace(',', '，') }}
				</template>
			</el-table-column>
			<el-table-column label="创建时间">
				<template slot-scope="scope">
					{{ scope.row.createDate | formatDate }}
				</template>
			</el-table-column>
			<el-table-column label="状态" width="70">
				<template slot-scope="scope">
					{{ scope.row.state === 0 ? '正常' : '禁用' }}
				</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right" width="300">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'userUser', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'userUser', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
						v-if="
							(currentUserOrganization != 0 && scope.row.roleIdsList.indexOf($envConfig.factoryAdminRoleId) === -1) ||
							(currentUserOrganization === 0 && scope.row.roleIdsList.indexOf($envConfig.superAdminRoleId) === -1)
						"
					>
						删除
					</el-button>
					<el-button
						id="btn-secret-row"
						type="success"
						v-authorize="{ name: 'secret', type: 'userUser', id: 'btn-secret-row' }"
						@click="editPasswordHandler(scope.row)"
					>
						修改密码
					</el-button>
					<!-- <el-button v-if="scope.row.organization === null" type="success" @click="setConfigHandler(scope.row)">设置权限</el-button> -->
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<UserAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:organizationId_p="organizationId"
				:editId_p="editId"
			></UserAddForm>
		</Dialog>
		<Dialog title="修改密码" :visible.sync="isShowPasswordDialog">
			<UserPasswordForm
				v-if="isShowPasswordDialog"
				:isShowPasswordDialog_p.sync="isShowPasswordDialog"
				:isRefreshList_p.sync="isRefreshList"
				:editId_p="editId"
				:userName_p="userName"
			></UserPasswordForm>
		</Dialog>
		<Dialog title="设置权限" :visible.sync="isShowConfigDialog">
			<el-form class="orglist">
				<el-form-item label="选择所辖企业">
					<el-select v-model="orgList" @change="changeHandle" multiple filterable placeholder="请选择所辖企业" class="select-item" clearable>
						<el-option
							v-for="(item, index) in orgData"
							:key="index"
							:label="item.organizationName"
							:value="item.id"
							:disabled="item.disabled"
						></el-option>
					</el-select>
				</el-form-item>
				<!-- <el-button type="primary" style="width: 80px">保存权限</el-button> -->
				<el-col :span="24" style="text-align: right">
					<span class="dialog-footer" style="text-align: right">
						<el-button @click="userFormCancel">取 消</el-button>
						<el-button type="primary" @click="saveConfig">确 定</el-button>
					</span>
				</el-col>
			</el-form>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { userDeleteService, userListService, userEditService, userDetailService } from '@s/system/UserService';
// eslint-disable-next-line import/no-cycle
// import { taxEnterpriseListService } from '@s/taxEnterprise/taxEnterpriseService';
import { hgetStorage } from '@/util/htools.web';
import Button from '@c/ui/Button';
import UserSearchform from '@f/system/user/UserSearch.form';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import Dialog from '@c/ui/Dialog';
import UserAddForm from '@f/system/user/UserAdd.form';
import UserPasswordForm from '@f/system/user/UserPassword.form';
import ListMixin from '@m/List.mixin';

export default {
	mixins: [ListMixin],
	components: {
		UserSearchform,
		Button,
		Table,
		Pagination,
		Dialog,
		UserAddForm,
		UserPasswordForm
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '用户名',
					field: 'userName'
				},
				{
					label: '企业名称',
					field: 'organizationName'
				},
				{
					label: '手机号',
					field: 'telphone'
				},
				{
					label: '昵称',
					field: 'nickName'
				},
				// {
				// 	label: '角色',
				// 	// field: 'userRoles'
				// 	field: 'roleList'
				// },
				{
					label: '备注',
					field: 'remark'
				}
			],
			delTips: '',
			isShowPasswordDialog: false,
			isShowConfigDialog: false,
			currentUserOrganization: null,
			organizationId: '', // 编辑给子组件传参用
			orgData: [],
			orgList: [],
			editDisabled: true
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增用户' : '编辑用户';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.userList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.userList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.userList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.userList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.userList();
		this.currentUserOrganization = hgetStorage('organization');
	},
	methods: {
		changeHandle(e) {
			if (e.includes(0)) {
				this.orgList = [0];
				this.orgData.forEach((item, index) => {
					item.disabled = true;
					if (index === 0) {
						item.disabled = false;
					}
				});
				console.log(this.orgData, '=======');
			} else {
				this.orgData.forEach(item => {
					item.disabled = false;
				});
			}
		},
		async userList() {
			let dataJson;
			if (hgetStorage('organization')) {
				dataJson = {
					pageNum: this.pageIndex,
					pageSize: this.pageSize,
					userName: '',
					organizationId: hgetStorage('organization'),
					...this.searchForm
				};
			} else {
				dataJson = {
					pageNum: this.pageIndex,
					pageSize: this.pageSize,
					userName: '',
					...this.searchForm
				};
			}
			const res = await userListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				userId: editId
			};
			const res = await userDetailService(dataJson);
			// res.organizationId = res.organization.id;
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			let admin;
			this.selectData.forEach(item => {
				if (item.roleList) {
					item.roleList.forEach(item1 => {
						if (item1.id === 1) {
							admin = item.id;
						}
					});
				}
			});
			const ids = this.filterSelectIdsMixin();
			const index = ids.indexOf(admin);
			if (ids.length === 1 && index > -1) {
				this.$message.warning('管理员不可删除');
				return false;
			}
			if (index > -1) {
				ids.splice(index, 1);
			}
			const dataJson = {
				ids: ids
			};
			await userDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				userId: row.id
			};
			const res = await userDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.userList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.id;
			this.userName = row.userName;
		},
		// 设置权限
		async setConfigHandler(row) {
			this.editId = row.id;
			this.getEnterprise();
			const dataJson = {
				userId: row.id
			};
			const res = await userDetailService(dataJson);
			if (res.dataRange !== null && res.dataRange !== '') {
				this.orgList = res.dataRange.split(',').map(Number);
			}
			this.isShowConfigDialog = true;
		},
		// 获取企业列表
		async getEnterprise() {
			const dataJson = {
				pageSize: 100000
			};
			const res = await taxEnterpriseListService(dataJson);
			this.orgData = res.records;
			this.orgData.unshift({
				id: 0,
				organizationName: '全部'
			});
		},
		userFormCancel() {
			this.isShowConfigDialog = false;
		},
		async saveConfig() {
			const dataJson = {
				id: this.editId,
				dataRange: this.orgList.join(',')
			};
			await userEditService(dataJson);
			this.isShowConfigDialog = false;
		}
	}
};
</script>

<style lang="less" scoped>
.orglist {
	height: 400px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}
</style>
