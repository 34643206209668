<template>
	<el-form ref="unitFormEle" :model="unitFormData" :rules="unitFormRules" size="small" label-width="100px">
		<el-form-item label="计量单位" prop="name">
			<el-input v-model="unitFormData.name" placeholder="请输入计量单位" />
		</el-form-item>
		<el-form-item>
			<div style="text-align: right">
				<span class="dialog-footer">
					<el-button @click="unitFormCancel">取 消</el-button>
					<el-button type="primary" @click="unitFormSubmit">确 定</el-button>
				</span>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
// eslint-disable-next-line import/named
import { unitAddService, unitEditService } from '@s/base/UnitService';

export default {
	props: ['selectData_p'],
	data() {
		return {
			editId: -1,
			unitFormData: {
				name: ''
			},
			unitFormRules: {
				name: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				]
			}
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.unitFormData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {},
	methods: {
		unitFormSubmit() {
			this.$refs.unitFormEle.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await unitAddService(this.unitFormData);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.unitFormData
						};
						await unitEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.unitFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
		},
		unitFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style>
</style>
