import { Message, Loading } from 'element-ui';
import axios from 'axios';
import { hgetStorage, hremoveStorage, huploadConfigJson } from './htools.web';
// eslint-disable-next-line import/no-cycle
import router from '../router/index';

const request = axios.create();

/* 读取接口提示信息 */
export const readyApiMsg = (msgArrData, code, childCode) => {
	if (code !== 200) {
		return msgArrData[code];
	}
	const msgArr_200 = msgArrData[code];
	return childCode ? msgArr_200[childCode] : '出大错了！';
};

/*
 *@ClassAuthor: huhulove
 *@Email: 2373838484@qq.com
 *@Date: 2020-10-22 17:41:14
 *@Description: axios - get 方法
 */
export function hgetRequest(url, params = {}) {
	return new Promise((resolve, reject) => {
		request
			.get(url, {
				params: params
			})
			.then(response => {
				resolve(response);
			})
			.catch(err => {
				reject(err);
			});
	});
}

/*
 *@ClassAuthor: huhulove
 *@Email: 2373838484@qq.com
 *@Date: 2020-10-22 17:45:53
 *@Description: axios - post 方法
 */
export function hpostRequest(url, data = {}) {
	return new Promise((resolve, reject) => {
		request.post(url, data).then(
			response => {
				resolve(response);
			},
			err => {
				reject(err);
			}
		);
	});
}

/*
 *@ClassAuthor: huhulove
 *@Email: 2373838484@qq.com
 *@Date: 2020-10-22 17:46:20
 *@Description: axios - patch 方法
 */
export function hpatchRequest(url, data = {}) {
	return new Promise((resolve, reject) => {
		request.patch(url, data).then(
			response => {
				resolve(response);
			},
			err => {
				reject(err);
			}
		);
	});
}

/*
 *@ClassAuthor: huhulove
 *@Email: 2373838484@qq.com
 *@Date: 2020-10-22 17:46:38
 *@Description: axios - put 方法
 */
export function hputRequest(url, data = {}) {
	return new Promise((resolve, reject) => {
		request.put(url, data).then(
			response => {
				resolve(response);
			},
			err => {
				reject(err);
			}
		);
	});
}

/*
 *@ClassAuthor: huhulove
 *@Email: 2373838484@qq.com
 *@Date: 2020-10-22 17:39:31
 *@Description: axios - 请求拦截器
 */
let envConfig = null;
let loading = null;
request.interceptors.request.use(
	async config => {
		if (!envConfig) {
			envConfig = await huploadConfigJson();
		}
		config.headers = {
			Authorization: hgetStorage('token')
		};
		if (config.url.indexOf('hlxpeng/') > -1) {
			config.url = config.url.replace('hlxpeng/', envConfig.lxpeng);
		} else if (config.url.indexOf('hlzlong/') > -1) {
			config.url = config.url.replace('hlzlong/', envConfig.lzlong);
		} else if (config.url.indexOf('hbtyong/') > -1) {
			config.url = config.url.replace('hbtyong/', envConfig.btyong);
		} else if (config.url.indexOf('hbtyong_1/') > -1) {
			config.url = config.url.replace('hbtyong_1/', envConfig.btyong_1);
		} else if (config.url.indexOf('horder/') > -1) {
			config.url = config.url.replace('horder/', envConfig.orderUrl);
		} else if (config.url.indexOf('hbase/') > -1) {
			config.url = config.url.replace('hbase/', envConfig.baseDataUrl);
		} else if (config.url.indexOf('hdevice:') > -1) {
			config.url = config.url.replace('hdevice:', envConfig.deviveUrl);
		} else if (config.url.indexOf('htax/') > -1) {
			config.url = config.url.replace('htax/', envConfig.taxUrl);
		} else if (config.url.indexOf('hsta/') > -1) {
			config.url = config.url.replace('hsta/', envConfig.staUrl);
		} else if (config.url.indexOf('hlog/') > -1) {
			config.url = config.url.replace('hlog/', envConfig.logUrl);
		} else if (config.url.indexOf('hdeli/') > -1) {
			config.url = config.url.replace('hdeli/', envConfig.hdeli);
		} else if (config.url.indexOf('hexport/') > -1) {
			config.url = config.url.replace('hexport/', envConfig.hexport);
		} else if (config.url.indexOf('imgHttp/') > -1) {
			config.url = config.url.replace('imgHttp/', envConfig.imgHttp);
		} else if (config.url.indexOf('hwms/') > -1) {
			config.url = config.url.replace('hwms/', envConfig.wms);
		} else {
			config.url = `${envConfig.baseUrl}${config.url}`;
		}
		loading = Loading.service({ lock: true, text: '正在加载...', spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.7)', fullscreen: true });
		return config;
	},
	error => {
		loading.close();
		return Promise.reject(error);
	}
);

/*
 *@ClassAuthor: huhulove
 *@Email: 2373838484@qq.com
 *@Date: 2020-10-22 17:40:54
 *@Description: axios - 响应拦截器
 */
let apiMsg = null;
(async () => {
	const result = await hgetRequest('imgHttp/frontend/apiMsg.json', {});
	loading.close();
	apiMsg = result.data;
})();

request.interceptors.response.use(
	response => {
		loading.close();
		const { code } = response.data;
		if (code === 201) {
			/* const { msg } = response.data; */
			Message.success('操作成功');
			return response.data.result;
		}
		return response.data.result;
	},
	async error => {
		console.log(error.response);
		const { status } = error.response;
		const { code } = error.response.data;
		loading.close();
		// Message.closeAll();
		if (status === 401) {
			// 未授权
			router.push({
				path: '/login',
				querry: { redirect: router.currentRoute.fullPath } // 从哪个页面跳转
			});
			hremoveStorage('token');
			return Promise.reject();
		}
		const msg = readyApiMsg(apiMsg, code);
		if (code === 499) {
			const { msg } = error.response.data;
			Message.error(msg);
			return Promise.reject(error);
		}
		Message.error(msg || '请求失败');
		return Promise.reject(error);
	}
);
