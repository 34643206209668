<template>
	<el-form
		ref="companyForm"
		:model="companyForm"
		:rules="companyRules"
		size="small"
		label-width="100px"
	>
		<el-form-item label="机构名称" prop="name">
			<el-input v-model="companyForm.name" placeholder="请输入机构名称" />
		</el-form-item>
		<el-form-item label="联系电话" prop="phone">
			<el-input v-model="companyForm.phone" maxlength="13" placeholder="请输入联系电话" />
		</el-form-item>
		<el-form-item label="用户名" prop="userName" v-if="editId === -1">
			<el-input v-model="companyForm.userName" placeholder="请输入用户名" />
		</el-form-item>
		<el-form-item label="密码" prop="password" v-if="editId === -1">
			<el-input v-model="companyForm.password" type="password" placeholder="请输入密码" />
		</el-form-item>
		<el-form-item label="维修范围" prop="scopeMaintenanceId">
			<el-input v-model="companyForm.scopeMaintenanceId" placeholder="请输入维修范围" />
		</el-form-item>
		<el-form-item label="官方网址" prop="website">
			<el-input v-model="companyForm.website" placeholder="请输入官方网址" />
		</el-form-item>
		<el-form-item label="公司LOGO" prop="website">
			<Upload
				:action="uploadLogoAction"
				class="isShowUpload"
				:limit="1"
				list-type="picture-card"
				:file-list="logoFile"
				:on-success="handlerLogoSuccess"
				:on-remove="handleLogoRemove"
				:isShowTip_p="true"
			>
				<p>公司logo只能上传一张图片</p>
			</Upload>
		</el-form-item>
		<el-form-item label="营业执照" prop="website">
			<Upload
				:action="uploadLicenseAction"
				class="isShowUpload1"
				:limit="1"
				list-type="picture-card"
				:file-list="licenseFile"
				:on-success="handlerLicenseSuccess"
				:on-remove="handleLicenseRemove"
				:isShowTip_p="true"
			>
				<p>营业执照只能上传一张图片</p>
			</Upload>
		</el-form-item>

		<el-form-item label="省" prop="province" style="width:25%">
			<el-select v-model="companyForm.province" placeholder="请选择省" @change="handlerProvince($event)">
				<el-option
					v-for="item in provinceList"
					:key="item.proCode"
					:label="item.proName"
					:value="item.proCode"
				></el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="市" prop="city" style="width:25%">
			<el-select v-model="companyForm.city" placeholder="请选择市" @change="handlerCity($event)">
				<el-option
					v-for="item in cityList"
					:key="item.proCode"
					:label="item.proName"
					:value="item.proCode"
				></el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="县" prop="county" style="width:30%">
			<el-select v-model="companyForm.county" placeholder="请选择县" style="width: 70%">
				<el-option
					v-for="item in countyList"
					:key="item.proCode"
					:label="item.proName"
					:value="item.proCode"
				></el-option>
			</el-select>
		</el-form-item>
		<el-form-item label="地址详情" prop="address">
			<div class="address">
				<el-input v-model="companyForm.address" placeholder="请输入地址详情" style="width: 200px" />
				<el-button type="primary" @click="addressHandler" style="margin-left: 20px">查询</el-button>
			</div>
		</el-form-item>

		<div style="width:100%; float:left; clear:both;"></div>
		<el-form-item label="纬度" style="display:none;">
			<el-input readonly type="number" v-model="companyForm.latitude" placeholder="请输入纬度" />
		</el-form-item>
		<el-form-item label="经度" style="display:none;">
			<el-input readonly type="number" v-model="companyForm.longitude" placeholder="请输入经度" />
		</el-form-item>
		<div style="width:100%; height:320px; display: flex;position:relative;">
			<div class="map" id="Map" style="width: 100%; height: 100%"></div>
			<div
				v-show="companyForm.latitude"
				style="position:absolute;right:0;bottom:0;z-index:9999;background:rgba(0,0,0,0.2)"
			>
				纬度:{{companyForm.latitude}}
				<br />
				经度:{{companyForm.longitude}}
			</div>
			<div
				id="r-result"
				ref="mapResult"
				style="width: 44%; height: 100%;position:absolute;left:0;top:0;z-index:9999"
			></div>
		</div>
		<el-form-item style="width:100%;">
			<div style="text-align: right;margin-top:15px;">
				<span class="dialog-footer">
					<el-button @click="companyFormCancel">取 消</el-button>
					<el-button type="primary" @click="companyFormSubmit">确 定</el-button>
				</span>
			</div>
		</el-form-item>
	</el-form>
</template>

<script>
// eslint-disable-next-line import/named
import { companyAddService, companyEditService } from '@s/system/CompanyService';
import { regionService, cityService, grountService } from '@s/region/regionService';
import Upload from '@c/ui/Upload.vue';

export default {
	props: ['selectData_p', 'editId_p'],
	components: { Upload },
	data() {
		const telValid = (rule, value, callback) => {
			const reg = /^(?:(?:\d{3}-)?\d{8}|^(?:\d{4}-)?\d{7,8})(?:-\d+)?$/;
			const reg1 = /^(?:(?:\+|00)86)?1\d{10}$/;
			if (!reg.test(value) && !reg1.test(value)) {
				callback(new Error('请输入正确的联系方式'));
			} else {
				callback();
			}
		};
		return {
			map: null,
			fileList: [],
			selectedOptions: [],
			provinceList: [],
			cityList: [],
			countyList: [],
			editId: -1,
			companyForm: {
				name: '',
				phone: '',
				userName: '',
				password: '',
				scopeMaintenanceId: '',
				website: '',
				address: '',
				province: '',
				city: '',
				county: '',
				logo: '',
				businessLicense: '',
				latitude: '',
				longitude: ''
			},
			companyRules: {
				name: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				],
				phone: [
					{
						required: true,
						message: '请输入联系方式',
						trigger: 'blur'
					},
					{
						validator: telValid,
						trigger: 'blur'
					}
				],
				userName: [
					{
						required: true,
						message: '请输入用户名',
						trigger: 'blur'
					}
				],
				password: [
					{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}
				],
				province: [
					{
						required: true,
						message: '请选择省',
						trigger: 'change'
					}
				],
				city: [
					{
						required: true,
						message: '请选择市',
						trigger: 'change'
					}
				],
				county: [
					{
						required: true,
						message: '请选择县',
						trigger: 'change'
					}
				]
			},
			uploadLogoAction: this.$envConfig.btyong_1 + this.$envConfig.uploadCompanyLogo,
			uploadLicenseAction: this.$envConfig.btyong_1 + this.$envConfig.uploadCompanyLicense,
			/* orgLogo: '',
			orgbusinesslicense: '', */
			logoFile: [],
			licenseFile: []
		};
	},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.logoFile = [];
					this.licenseFile = [];
					this.editId = newValue[0].id;
					newValue[0].province && (await this.handlerProvince(newValue[0].province));
					newValue[0].city && (await this.handlerCity(newValue[0].city));
					newValue[0].province = Number(newValue[0].province);
					newValue[0].city = Number(newValue[0].city);
					newValue[0].county = Number(newValue[0].county);
					this.logoFile.push({
						name: newValue[0].logo,
						url: `${this.$envConfig.imgHttp}${this.$envConfig.imgFile.orglogo}/${newValue[0].logo}`
					});
					this.licenseFile.push({
						name: newValue[0].businessLicense,
						url: `${this.$envConfig.imgHttp}${this.$envConfig.imgFile.orgbusinesslicense}/${newValue[0].businessLicense}`
					});
					this.companyForm = { ...newValue[0] };

					if (this.companyForm.latitude) {
						setTimeout(() => {
							// 创建地图实例
							const point = new BMapGL.Point(this.companyForm.longitude, this.companyForm.latitude);
							// 创建点坐标
							this.map.centerAndZoom(point, 16);

							const marker = new BMapGL.Marker(point);
							this.map.addOverlay(marker);
						}, 1000);
					}

					setTimeout(() => {
						this.isShowUpload();
						this.isShowUpload1();
					}, 10);
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		this.getRegion();
	},
	mounted() {
		this.baiduMap();
	},
	methods: {
		isShowUpload() {
			const isShowUpload = document.querySelector('.isShowUpload .el-upload--picture-card');
			if (this.companyForm.logo === '') {
				isShowUpload.style.display = 'inline-block';
			} else {
				isShowUpload.style.display = 'none';
			}
		},
		isShowUpload1() {
			const isShowUpload = document.querySelector('.isShowUpload1 .el-upload--picture-card');
			if (this.companyForm.businessLicense === '') {
				isShowUpload.style.display = 'inline-block';
			} else {
				isShowUpload.style.display = 'none';
			}
		},
		handlerLogoSuccess(res) {
			this.companyForm.logo = res.result;
			this.isShowUpload();
		},
		handleLogoRemove() {
			this.companyForm.logo = '';
			this.isShowUpload();
		},
		handlerLicenseSuccess(res) {
			this.companyForm.businessLicense = res.result;
			this.isShowUpload1();
		},
		handleLicenseRemove() {
			this.companyForm.businessLicense = '';
			this.isShowUpload1();
		},
		// 获取省市县数据
		async getRegion() {
			const res = await regionService();
			this.provinceList = res;
		},
		// 省选择玩显示市list
		async handlerProvince(e) {
			this.provinceList.forEach(item => {
				if (Number(item.proCode) === Number(e)) {
					this.addressProvince = item.proName;
				}
			});
			const dataJson = {
				regionNum: e
			};
			const res = await cityService(dataJson);
			this.cityList = res;
			console.log(res);
		},
		// 市选择玩显示区list
		async handlerCity(e) {
			const dataJson = {
				regionNum: e
			};
			const res = await grountService(dataJson);
			this.countyList = res;
			console.log(res);
		},
		companyFormSubmit() {
			this.$refs.companyForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await companyAddService(this.companyForm);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.companyForm
						};
						await companyEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.companyFormCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
					this.$emit('update:isRefreshListAll_p', false);
				}
			});
		},
		companyFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		},
		// 地图经纬度问题 -----------S--------------
		baiduMap() {
			this.map = new BMapGL.Map('Map');
			// 创建地图实例
			const point = new BMapGL.Point(116.41338678224898, 39.91270163195107);
			// 创建点坐标
			this.map.centerAndZoom(point, 10);
			// 开启鼠标滚轮缩放
			this.map.enableScrollWheelZoom(true);
		},
		addressHandler() {
			this.map.clearOverlays();
			const local = new BMapGL.LocalSearch(this.map, {
				renderOptions: { map: this.map, panel: 'r-result' },
				pageCapacity: 5
			});
			if (this.addressProvince) {
				local.search(this.addressProvince + this.companyForm.address);
			} else {
				local.search(this.companyForm.address);
			}
			local.setInfoHtmlSetCallback(e => {
				// 创建地图实例
				const point = new BMapGL.Point(Number(e.point.lng) - 0.0008, e.point.lat);
				// 创建点坐标
				setTimeout(() => {
					this.map.centerAndZoom(point, 19);
				}, 400);
				this.companyForm.latitude = e.point.lat;
				this.companyForm.longitude = e.point.lng;
			});
		}
		// 地图经纬度问题 -----------E--------------
	}
};
</script>

<style lang='less' scoped>
</style>
