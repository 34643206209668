<template>
	<div class="app-container">
		<CompanySearchForm @searchForm="searchFormHandler"></CompanySearchForm>
		<Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'company'"
		></Button>
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<!-- (1:医院 2:维修公司) -->
			<el-table-column label="LOGO">
				<template slot-scope="scope">
					
					<el-image
						class="devBaseFormLogoStr"
						style="width:30px;height:30px; "
						:src="orgLogo + scope.row.logo"
						:preview-src-list="[orgLogo + scope.row.logo]"
					></el-image>
					<!-- <img :src="orgLogo + scope.row.logo" width="30" height="30" alt="" srcset="" /> -->
				</template>
			</el-table-column>
			<el-table-column label="创建时间">
				<template slot-scope="scope">{{ scope.row.createDate | formatDate }}</template>
			</el-table-column>

			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'company', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'company', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<CompanyAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></CompanyAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { companyListService, companyDeleteService, companyDetailService } from '@s/system/CompanyService';
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import CompanyAddForm from '@f/system/company/CompanyAdd.form';
import CompanySearchForm from '@f/system/company/CompanySearch.form';
import ListMixin from '@m/List.mixin';

export default {
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		Button,
		CompanyAddForm,
		CompanySearchForm
	},
	data() {
		return {
			orgLogo: '',
			orgbusinesslicense: '',
			// 表格
			tableColumn: [
				{
					label: '机构名称',
					field: 'name',
					width: 200
				},
				{
					label: '官方网址',
					field: 'website'
				},
				{
					label: '联系电话',
					field: 'phone'
				},
				{
					label: '地址详情',
					field: 'address'
				},
				{
					label: '维修范围',
					field: 'scopeMaintenanceId'
				}
			],
			delTips: ''
		};
	},

	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增维修公司' : '编辑维修公司';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.companyList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.companyList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.companyList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.companyList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.companyList();
		setTimeout(() => {
			this.orgLogo = `${this.$envConfig.imgHttp}${this.$envConfig.imgFile.orglogo}/`;
			this.orgbusinesslicense = `${this.$envConfig.imgHttp}${this.$envConfig.imgFile.orgbusinesslicense}/`;
		}, 1000);
	},
	methods: {
		async companyList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await companyListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				companyId: editId
			};
			const res = await companyDetailService(dataJson);
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				id: ids
			};
			await companyDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				companyId: row.id
			};
			const res = await companyDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.companyList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.id;
		}
	}
};
</script>
<style lang='less' scoped>
/deep/.el-image-viewer__close {
	top: 80px !important;
	color: #ffffff;
}
/deep/.el-image__error{
	line-height: 14px;
}
/deep/ .el-dialog {
	width: 1000px;
	overflow: hidden;
}
/deep/.el-dialog .el-form-item {
	width: 45%;
	margin-right: 15px;
	float: left;
}
</style>
