import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 医院列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const hospitalListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				...data
			};
			const res = await hpostRequest('hbtyong_1/hospital/organizationAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院列表所有
 *@ClassAuthor: myw
 *@Date: 2021-05-20 16:38:05
 */
 export const hospitalListAllService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hbtyong_1/hospital/hospitalSelectAll', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院添加
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:34
 */
export const hospitalAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				address: '',
				city: '',
				county: '',
				name: '',
				levelId: '', 
				phone: '', 
				province: 0,
				website: '',
				userName: '',
				password: '',
				...data
			};
			const res = await hpostRequest('hbtyong_1/hospital/organizationAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院修改
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:53
 */
export const hospitalEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				address: '',
				city: '',
				county: '',
				name: '',
				levelId: '', 
				phone: '', 
				province: 0,
				website: '',
				...data
			};
			const res = await hpostRequest('hbtyong_1/hospital/organizationUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const hospitalDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				hospitalId: '',
				...data
			};
			const res = await hgetRequest('hbtyong_1/hospital/organizationByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院删除
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:33
 */
export const hospitalDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: [],
				...data
			};
			const res = await hpostRequest('hbtyong_1/hospital/organizationDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default hospitalListService;
