import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 管理方式列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   managerStyle
 */
export const managerStyleListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/goodsManageType/all', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院等级添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:00:29
 */
export const hospitalLevelAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				grade: '',
				...data
			};
			const res = await hpostRequest('hlzlong/hospitalGrade/hospitalGradeAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 管理方式编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:58
 */
export const managerStyleEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hpostRequest('hwms/goodsManageType/Update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 管理方式详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:59:21
 */
export const managerStyleDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hwms/goodsManageType/getById', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院等级删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:03
 */
export const hospitalLevelDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest('hlzlong/hospitalGrade/hospitalGradeDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default managerStyleListService;
