<template>
	<div class="login-container">
		<div class="login">
			<div class="login_top">
				<img class="logo2" src="../assets/logo2.png" alt />
				<h3 class="h3cn">医疗设备智能运维平台</h3>
				<h3 class="h3en">
					Medical equipment intelligent operation
					<br />and maintenance cloud platform
				</h3>
				<div class="line"></div>
				<h2 class="h2cn">{{ tabLogin[imgActive].titleCn }}</h2>
				<h2 class="h2en">{{ tabLogin[imgActive].titleEn }}</h2>
			</div>
			<el-form
				ref="loginForm"
				:model="loginForm"
				:rules="loginRules"
				class="login-form"
				auto-complete="on"
				label-position="right"
				label-width="80px"
				:hide-required-asterisk="true"
			>
				<div class="title-container">
					<h3 class="title">医疗设备智能运维平台管理员后台</h3>
				</div>
				<el-form-item prop="userName" label="username">
					<el-input
						ref="userName"
						v-model="loginForm.userName"
						placeholder="请输入用户名"
						name="username"
						type="text"
						tabindex="1"
						auto-complete="on"
					/>
				</el-form-item>
				<el-form-item prop="userPwd" label="password">
					<el-input
						key="userPwd"
						ref="userPwd"
						v-model="loginForm.userPwd"
						type="password"
						placeholder="请输入密码"
						name="userPwd"
						tabindex="2"
						auto-complete="on"
					/>
				</el-form-item>
				<el-button
					:loading="loading"
					type="primary"
					class="loginBtn"
					@click.native.prevent="handleLogin"
				>登录</el-button>
			</el-form>

			<div class="tabLogin">
				<ul>
					<li
						v-for="item in tabLogin"
						:key="item.id"
						:data-obj="JSON.stringify(item)"
						@click="handlerTabLogin"
						:class="[imgActive === item.id ? '' : 'active']"
					>
						<img :src="item.imgNow" :data-obj="JSON.stringify(item)" />
						<br />
						<span :data-obj="JSON.stringify(item)">{{ item.name }}</span>
					</li>
				</ul>
			</div>
			<div class="bgBottom"></div>
			<div class="bgLoginShadow"></div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Login',
	data() {
		return {
			loginForm: {
				userName: '',
				userPwd: ''
			},
			loginRules: {
				userName: [{ required: true, trigger: 'blur', message: '请输入用户名' }],
				userPwd: [{ required: true, trigger: 'blur', message: '请输入密码' }]
			},
			loading: false,
			redirect: undefined,

			imgActive: 0,
			tabLogin: [
				{
					id: 0,
					titleCn: '管理员管理系统',
					titleEn: 'ADMIN MANAGEMENT SYSTEM',
					name: '管理员登录',
					imgNow: require('../assets/bgLoginAdminA.png'),
					img: require('../assets/bgLoginAdmin.png'),
					imgA: require('../assets/bgLoginAdminA.png')
				}
				// {
				// 	id: 1,
				// 	titleCn: '维保管理系统',
				// 	titleEn: 'REPAIR MANAGEMENT SYSTEM',
				// 	name: '维修登录',
				// 	imgNow: require('../assets/bgLoginRepair.png'),
				// 	img: require('../assets/bgLoginRepair.png'),
				// 	imgA: require('../assets/bgLoginRepairA.png')
				// }
			]
		};
	},
	watch: {
		$route: {
			handler(route) {
				this.redirect = route.query && route.query.redirect;
			},
			immediate: true
		}
	},
	mounted() {
		document.onkeydown = () => {
			const key = window.event.keyCode;
			if (key === 13) {
				this.handleLogin();
			}
		};
	},
	methods: {
		handlerTabLogin(e) {
			this.loginForm = {};
			this.$refs.loginForm.resetFields();

			const chooseData = JSON.parse(e.target.dataset.obj);
			this.imgActive = chooseData.id;
			this.tabLogin.forEach(item => {
				item.imgNow = item.img;
			});
			this.tabLogin[this.imgActive].imgNow = this.tabLogin[this.imgActive].imgA;
		},
		handleLogin() {
			this.$refs.loginForm.validate(async valid => {
				if (valid) {
					this.loading = true;
					try {
						await this.$store.dispatch('loginStore', this.loginForm);
						this.$router.push({ path: this.redirect || '/' });
						this.loading = false;
					} catch (error) {
						this.loading = false;
					}
				} else {
					console.log('error submit!!');
					return false;
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
.login-container {
	min-height: 100%;
	width: 100%;
	background-color: #2d3a4b;
	overflow: hidden;
	// background: url(../assets/login_bg.jpg) no-repeat;
	background: url(../assets/bgLogin.jpg) no-repeat;
	display: flex;
	justify-content: center;
	align-items: center;
	.login-form {
		position: relative;
		width: 100%;
		margin: 0 auto;
		overflow: hidden;
	}
	.tips {
		font-size: 14px;
		color: #fff;
		margin-bottom: 10px;
		span {
			&:first-of-type {
				margin-right: 16px;
			}
		}
	}
	.title-container {
		position: relative;
		.title {
			font-size: 26px;
			color: #fff;
			margin: 0px auto 40px auto;
			text-align: center;
			font-weight: bold;
		}
	}
	.show-pwd {
		position: absolute;
		right: 10px;
		top: 7px;
		font-size: 16px;
		color: #fff;
		cursor: pointer;
		user-select: none;
	}
}

/deep/ .el-form-item {
	width: 360px;
	// height: 58px;
	padding: 10px;
	margin: 20px auto;
	background: #e6fbff;
	border-radius: 10px;
}
/deep/ .el-form-item__label {
	color: #7cceda;
}
/deep/ .el-input--small .el-input__inner {
	background: #e6fbff;
	border: none;
	color: #333333;
}
/deep/.el-form-item__error {
	margin-top: 12px;
	margin-left: 15px;
}
.h3cn {
	font-size: 22px;
	color: #0a1e39;
}
.h3en {
	font-size: 10px;
	color: #abbbd2;
	font-weight: normal;
}
.h2cn {
	font-size: 28px;
	color: #01d9ff;
}
.h2en {
	font-size: 10px;
	color: #6de4f8;
	font-weight: normal;
}
.login {
	width: 600px;
	height: 730px;
	background: #fff;
	position: relative;
	border-radius: 15px;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}
.login_top {
	text-align: center;
}
.login_top .logo2 {
	margin-top: 30px;
	margin-bottom: 10px;
}
.login_top .line {
	width: 90%;
	height: 1px;
	background: #f1f1f1;
	margin: 20px auto;
}
.tabLogin {
	width: 100%;
	position: absolute;
	bottom: 30px;
}
.tabLogin ul {
	display: flex;
	justify-content: center;
	text-align: center;
}
.tabLogin ul li {
	margin: 0 10px;
	color: #091f37;
	cursor: pointer;
	font-size: 14px;
}
.tabLogin ul li.active {
	color: #979998;
}
.loginBtn {
	width: 230px;
	height: 58px;
	margin-left: 185px;
	font-size: 16px;
	color: #01d9ff;
	background: #0a1e39;
	border-radius: 10px;
	font-weight: bold;
	letter-spacing: 40px;
}
/deep/ .loginBtn span {
	margin-left: 40px;
}
.loginBtn:hover {
	color: #1ed5f5;
	background: #133056;
}
.bgBottom {
	width: 100%;
	height: 45px;
	background: url('../assets/bgLoginBottom.png') no-repeat left bottom;
	position: absolute;
	bottom: 0;
}
.bgLoginShadow {
	width: 100%;
	height: 71px;
	background: url('../assets/bgLoginShadow.png') no-repeat left bottom;
	position: absolute;
	bottom: -74px;
	left: 56px;
}
</style>
