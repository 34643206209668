<template>
	<div>
		<el-form ref="elForm" :model="formData" :rules="rules" size="small" label-width="130px">
			<el-form-item label="名称" prop="grade">
				<el-input v-model="formData.name" placeholder="请输入名称" clearable :style="{ width: '100%' }"></el-input>
			</el-form-item>
			<el-form-item label="键" prop="paramKey">
				<el-input v-model="formData.paramKey" placeholder="请输入键" />
			</el-form-item>
			<el-form-item label="值" prop="paramValue">
				<el-input v-model="formData.paramValue" placeholder="请输入值" />
			</el-form-item>
			<el-form-item>
				<div style="text-align: right">
					<span class="dialog-footer">
						<el-button @click="formCancel">取 消</el-button>
						<el-button type="primary" @click="formSubmit">确 定</el-button>
					</span>
				</div>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { systemParamsAddService, systemParamsEditService } from '@s/base/SystemParamsService';

export default {
	components: {},
	props: ['selectData_p', 'isRefreshList_p'],
	data() {
		return {
			editId: -1,
			formData: {
				name: '',
				paramKey: '',
				paramValue: ''
			},
			rules: {
				name: [
					{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}
				],
				paramKey: [
					{
						required: true,
						message: '请输入键',
						trigger: 'blur'
					}
				],
				paramValue: [
					{
						required: true,
						message: '请输入值',
						trigger: 'blur'
					}
				]
			}
		};
	},
	computed: {},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {},
	methods: {
		formSubmit() {
			this.$refs.elForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await systemParamsAddService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await systemParamsEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>
<style>
</style>
