<template>
	<el-row>
		<el-col :span="24">
			<el-form ref="adminRoleForm" :model="adminRoleForm" :rules="adminRoleRules" label-width="80px">
				<!-- <el-form-item label="所属企业" prop="organizationId">
					<el-select v-model="adminRoleForm.organizationId" placeholder="请选择" class="select-item" :disabled="orgDisabled" clearable filterable>
						<el-option label="无" :value="0"></el-option>
						<el-option
							v-for="(enterprise, index) in enterpriseData"
							:key="index"
							:label="enterprise.organizationName"
							:value="enterprise.id"
						></el-option>
					</el-select>
				</el-form-item> -->
				<el-form-item label="名称" prop="roleName">
					<el-input v-model="adminRoleForm.roleName" placeholder="请输入角色名称" />
				</el-form-item>
				<el-form-item label="状态" prop="state">
					<el-radio-group style="width: 200px" v-model="adminRoleForm.state">
						<el-radio :label="0">正常</el-radio>
						<el-radio :label="1">禁用</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="描述">
					<el-input type="textarea" :rows="4" v-model="adminRoleForm.des" placeholder="请输入角色描述" />
				</el-form-item>
			</el-form>
		</el-col>
		<el-col :span="24" style="text-align: right">
			<span class="dialog-footer">
				<el-button @click="adminRoleFormCancel">取 消</el-button>
				<el-button type="primary" @click="adminRoleFormSubmit">确 定</el-button>
			</span>
		</el-col>
	</el-row>
</template>

<script>
import { adminRoleAddService, adminRoleEditService } from '@s/admin/AdminRoleService';
// import { enterpriseListService } from '@s/base/EnterpriseService';
import { hgetStorage } from '@/util/htools.web';

export default {
	props: ['selectData_p', 'editId_p'],
	data() {
		return {
			editId: -1,
			adminRoleForm: {
				roleName: '',
				state: 0,
				des: ''
			},
			adminRoleRules: {
				roleName: [
					{
						required: true,
						message: '请输入角色名称',
						trigger: 'blur'
					}
				],
				organizationId: [
					{
						required: true,
						message: '请选择企业',
						trigger: 'change'
					}
				],
				state: [
					{
						required: true,
						message: '请选择角色状态',
						trigger: 'blur'
					}
				]
			},
			orgDisabled: false, // 企业是否可选，admin必选， 企业不可选
			enterpriseData: [] // 企业列表
		};
	},
	watch: {
		selectData_p: {
			handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.adminRoleForm = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	created() {
		// this.enterpriseList();
		this.editId = this.editId_p;
		if (hgetStorage('organization')) {
			this.orgDisabled = true;
			this.adminRoleForm.organizationId = hgetStorage('organization');
		}
	},
	methods: {
		// 获取企业列表
		async enterpriseList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await enterpriseListService(dataJson);
			this.enterpriseData = res.records;
		},
		adminRoleFormSubmit() {
			this.$refs.adminRoleForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await adminRoleAddService(this.adminRoleForm);
						this.$emit('update:isRefreshListAll_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.adminRoleForm
						};
						await adminRoleEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.adminRoleFormCancel();
				} else {
					this.$emit('update:isRefreshListAll_p', false);
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		adminRoleFormCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>

<style>
</style>
