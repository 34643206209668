<template>
	<div>
		<el-form ref="elForm" :model="formData" :rules="rules" size="small" label-width="88px">
			<el-form-item label="名称" prop="name">
				<el-input v-model="formData.name" placeholder="请输入类目名称" clearable :style="{ width: '100%' }"></el-input>
			</el-form-item>
			<el-form-item label="类型" prop="inOutType">
				<el-select v-model="formData.inOutType" placeholder="请选择类型" :style="{ width: '100%' }">
					<el-option label="请选择" value=""></el-option>
					<el-option label="出库" :value="2"></el-option>
					<el-option label="入库" :value="1"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="是否启用" prop="enable">
				<el-switch v-model="formData.enable" active-color="#13ce66" inactive-color="#ff4949"></el-switch>
			</el-form-item>
			<el-form-item>
				<div style="text-align: right">
					<span class="dialog-footer">
						<el-button @click="formCancel">取 消</el-button>
						<el-button type="primary" @click="formSubmit">确 定</el-button>
					</span>
				</div>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { measureUnitAddService, issueReceiptTypeEditService } from '@s/base/IssueReceiptTypeService';

export default {
	components: {},
	props: ['selectData_p', 'isRefreshList_p'],
	data() {
		return {
			editId: -1,
			formData: {
				name: '',
				inOutType: '',
				enable: false
			},
			rules: {
				name: [
					{
						required: true,
						message: '请输入类目名称',
						trigger: 'blur'
					}
				],
				inOutType: [
					{
						required: true,
						message: '请选择出入库类型',
						trigger: 'change'
					}
				]
			}
		};
	},
	computed: {},
	watch: {
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.formData = { ...newValue[0] };
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {},
	methods: {
		formSubmit() {
			this.$refs.elForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await measureUnitAddService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await issueReceiptTypeEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		}
	}
};
</script>
<style>
</style>
