import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 设备列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   equipment
 */
export const equipmentListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				equipment: '',
				...data
			};
			const res = await hpostRequest('hbase/equipment/equipmentAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:00:29
 */
export const equipmentAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				electricityAfter: 0,
				electricityFront: 0,
				energy: '',
                equipment: '',
				codeId: '', // 设备类型
				processId: '', // 生产工艺
				isDel: 0,
				...data
			};
			const res = await hpostRequest('hbase/equipment/equipmentAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:58
*/
export const equipmentEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				consumptionNatural: 0,
				electricityAfter: 0,
				electricityFront: 0,
				energy: '',
                equipment: '',
                naturalAfter: '',
                naturalFront: '',
                powerConsumption: '',
				isDel: 0,
				...data
			};
			const res = await hpostRequest('hbase/equipment/equipmentInfoUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:59:21
 */
export const equipmentDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				equipmentId: '',
				...data
			};
			const res = await hgetRequest('hbase/equipment/equipmentByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 设备删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:03
*/
export const equipmentDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				equipmentId: [],
				...data
			};
			const res = await hpostRequest('hbase/equipment/equipmentDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default equipmentListService;
