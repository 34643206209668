<template>
	<div>
		<el-form ref="elForm" :model="formData" :rules="rules" size="small" label-width="130px">
			<el-form-item label="设备类型" prop="deviceTypeId">
				<!-- <el-select v-model="formData.deviceTypeId" placeholder="请选择设备类型" class="select-item">
					<el-option v-for="(item, index) in deviceTypeData" :key="index" :label="item.deviceType" :value="item.id"></el-option>
				</el-select> -->
				<el-select v-model="parentName" class="select-item" placeholder="请选择" collapse-tags clearable @clear="clearHandler">
					<el-option :value="formData.deviceTypeId" style="height: auto">
						<el-tree
							:data="deviceTypeData"
							node-key="id"
							ref="tree"
							highlight-current
							:props="defaultProps"
							accordion
							@node-click="nodeClickHandler"
						></el-tree>
					</el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="标准型号" prop="deviceModel">
				<el-input v-model="formData.deviceModel" placeholder="请输入型号" clearable :style="{ width: '100%' }"></el-input>
			</el-form-item>
			<!-- <el-form-item label="制造商" prop="deviceManufacturer">
				<el-input v-model="formData.deviceManufacturer" placeholder="请输入制造商" clearable :style="{ width: '100%' }"></el-input>
			</el-form-item> -->
			<el-form-item label="标准名称" prop="deviceName">
				<el-input v-model="formData.deviceName" placeholder="请输入标准名称" clearable :style="{ width: '100%' }"></el-input>
			</el-form-item>
			<el-form-item label="计量单位" prop="computeUnit">
				<el-select v-model="formData.computeUnit" placeholder="请选择计量单位" class="select-item">
					<el-option v-for="(item, index) in unitData" :key="index" :label="item.name" :value="item.id"></el-option>
				</el-select>
			</el-form-item>
			<el-form-item label="图片">
				<Upload
					:action="uploadAction"
					class="isShowUpload"
					:limit="1"
					list-type="picture-card"
					:file-list="fileList"
					:on-success="handleSuccess"
					:on-remove="handleRemove"
					:isShowTip_p="true"
				>
					<p>图片只能上传一张图片</p>
				</Upload>
			</el-form-item>
			<el-form-item>
				<div style="text-align: right">
					<span class="dialog-footer">
						<el-button @click="formCancel">取 消</el-button>
						<el-button type="primary" @click="formSubmit">确 定</el-button>
					</span>
				</div>
			</el-form-item>
		</el-form>
	</div>
</template>
<script>
// eslint-disable-next-line import/no-cycle
import { normAddService, normEditService } from '@s/base/NormService';
import { deviceTypeListService } from '@s/base/DeviceTypeService';
import { unitListService } from '@s/base/UnitService';
import Upload from '@c/ui/Upload.vue';
import { arrayIncludeItem } from '@u/index';
import { getTreeNodeById, changeTreeDataToChildren } from '@u/htools.tree';

export default {
	components: {
		Upload
	},
	props: ['selectData_p', 'isRefreshList_p', 'deviceTypeId_p'],
	data() {
		return {
			editId: -1,
			defaultProps: {
				children: 'children',
				label: 'deviceType'
			},
			formData: {
				deviceName: '',
				deviceManufacturer: '',
				deviceModel: '',
				deviceTypeId: ''
			},
			rules: {
				deviceName: [
					{
						required: true,
						message: '请输入故障名称',
						trigger: 'blur'
					}
				],
				deviceManufacturer: [
					{
						required: true,
						message: '请输入制造商',
						trigger: 'blur'
					}
				],
				computeUnit: [
					{
						required: true,
						message: '请输入计量单位',
						trigger: 'blur'
					}
				],
				deviceModel: [
					{
						required: true,
						message: '请输入型号',
						trigger: 'blur'
					}
				],
				deviceTypeId: [
					{
						required: true,
						message: '请选择设备类型',
						trigger: 'change'
					}
				]
			},
			parentName: '',
			deviceTypeData: null,
			fileList: [],
			unitData: [],
			uploadAction: this.$envConfig.lzlong + this.$envConfig.uploadNormImg
		};
	},
	created() {},
	watch: {
		deviceTypeId_p: {
			async handler(newValue) {
				console.log(newValue);
				this.formData.deviceTypeId = newValue;
				this.deviceTypeData === null && (await this.deviceTypeList());
				getTreeNodeById(this.deviceTypeData, 'id', newValue, node => {
					this.parentName = node.deviceType;
				});
			},
			deep: true,
			immediate: true
		},
		selectData_p: {
			async handler(newValue) {
				if (newValue.length > 0) {
					this.editId = newValue[0].id;
					this.fileList.push({
						name: newValue[0].picture,
						url: `${this.$envConfig.imgHttp}${this.$envConfig.imgFile.norm}/${newValue[0].picture}`
					});
					this.formData = { ...newValue[0] };
					setTimeout(() => {
						this.isShowUpload();
					}, 30);
					// 先同步获取一下所有列表数据 重点
					this.unitData === null && (await this.unitList());
					this.deviceTypeData === null && (await this.deviceTypeList());
					const result = arrayIncludeItem(this.unitData, newValue[0].computeUnit);
					if (!result) {
						this.formData.computeUnit = null;
					}
					/* const result1 = arrayIncludeItem(this.deviceTypeData, newValue[0].deviceTypeId);
					if (!result1) {
						this.formData.deviceTypeId = null;
					} */
					/* console.log(this.formData.deviceTypeId); */
					getTreeNodeById(this.deviceTypeData, 'id', this.formData.deviceTypeId, node => {
						this.parentName = node.deviceType;
					});
				}
			},
			deep: true,
			immediate: true
		}
	},
	mounted() {
		this.deviceTypeList();
		this.unitList();
	},
	methods: {
		clearHandler() {
			this.formData.deviceTypeId = null;
			this.parentName = '';
		},
		isShowUpload() {
			const isShowUpload = document.querySelector('.isShowUpload .el-upload--picture-card');
			if (this.formData.picture === '') {
				isShowUpload.style.display = 'block';
			} else {
				isShowUpload.style.display = 'none';
			}
		},
		async unitList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000
			};
			const res = await unitListService(dataJson);
			this.unitData = [...res.records];
		},
		handleSuccess(res) {
			this.formData.picture = res.result;
			this.isShowUpload();
		},
		handleRemove() {
			this.formData.picture = '';
			this.isShowUpload();
		},
		async deviceTypeList() {
			const dataJson = {
				pageNum: 1,
				pageSize: 100000,
				type: -1
			};
			const res = await deviceTypeListService(dataJson);
			this.deviceTypeData = changeTreeDataToChildren([...res.records]);
		},
		formSubmit() {
			this.$refs.elForm.validate(async valid => {
				if (valid) {
					if (this.editId === -1) {
						await normAddService(this.formData);
						this.$emit('update:isRefreshList_p', true);
					} else {
						const dataJson = {
							id: this.editId,
							...this.formData
						};
						await normEditService(dataJson);
						this.$emit('update:isRefreshList_p', true);
					}
					this.formCancel();
				} else {
					this.$emit('update:isRefreshList_p', false);
				}
			});
		},
		formCancel() {
			this.$emit('update:isShowAEDialog_p', false);
		},
		nodeClickHandler(data) {
			if (data.pid !== 0) {
				this.formData.deviceTypeId = data.id;
				this.parentName = data.deviceType;
			}
		}
	}
};
</script>
<style>
</style>
