import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 维修公司列表
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:02
 */
export const companyListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				...data
			};
			const res = await hpostRequest('hbtyong_1/company/organizationAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 维修公司列表所有
 *@ClassAuthor: myw
 *@Date: 2021-05-20 16:38:05
 */
export const companyListAllService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hbtyong_1/company/companySelectAll', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 维修公司添加
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:34
 */
export const companyAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				phone: '',
				userName: '',
				password: '',
				scopeMaintenanceId: '',
				website: '', 
				address: '',
				province: '',
				city: '',
				county: '',
				...data
			};
			const res = await hpostRequest('hbtyong_1/company/organizationAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 维修公司修改
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:29:53
 */
export const companyEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: '',
				phone: '',
				userName: '',
				password: '',
				scopeMaintenanceId: '',
				website: '', 
				address: '',
				...data
			};
			const res = await hpostRequest('hbtyong_1/company/organizationUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 维修公司详情
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:17
 */
export const companyDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				companyId: '',
				...data
			};
			const res = await hgetRequest('hbtyong_1/company/organizationByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 维修公司删除
 *@ClassAuthor: Happy ZXM
 *@Date: 2021-05-10 14:30:33
 */
export const companyDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: [],
				...data
			};
			const res = await hpostRequest('hbtyong_1/company/organizationDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default companyListService;
