<template>
	<div class="app-container">
		<UnitSearchForm @searchForm="searchFormHandler"></UnitSearchForm>
		<Button
			@showDialogAdd="showDialogAddHandler"
			@showDialogEdit="showDialogEditHandler"
			@showDelete="showDeleteHandler"
			:selectData_p="selectData"
			:delTips_p="delTips"
			:authorize_p="'unit'"
		></Button>
		<!--表格渲染-->
		<Table ref="tableDom" :data.sync="tableData" :tableColumn_p="tableColumn" :selectData_p.sync="selectData">
			<el-table-column label="创建时间">
				<template slot-scope="scope">{{ scope.row.createDate | formatDate }}</template>
			</el-table-column>
			<el-table-column label="操作" align="left" fixed="right">
				<template slot-scope="scope">
					<el-button
						id="btn-update-row"
						type="primary"
						v-authorize="{ name: 'update', type: 'unit', id: 'btn-update-row' }"
						@click="editSingleHandler(scope.row)"
					>
						编辑
					</el-button>
					<el-button
						id="btn-remove-row"
						type="danger"
						v-authorize="{ name: 'remove', type: 'unit', id: 'btn-remove-row' }"
						@click="deleteSingleHandler(scope.row)"
					>
						删除
					</el-button>
				</template>
			</el-table-column>
		</Table>
		<Pagination :total="total" :pageIndex_p.sync="pageIndex" :pageSize_p.sync="pageSize"></Pagination>
		<Dialog :title="dialogTitle" :visible.sync="isShowAEDialog">
			<UnitAddForm
				v-if="isShowAEDialog"
				:isShowAEDialog_p.sync="isShowAEDialog"
				:isRefreshList_p.sync="isRefreshList"
				:isRefreshListAll_p.sync="isRefreshListAll"
				:selectData_p="selectData"
				:editId_p="editId"
			></UnitAddForm>
		</Dialog>
	</div>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import { unitListService, unitDeleteService, unitDetailService } from '@s/base/UnitService';
import Dialog from '@c/ui/Dialog';
import Button from '@c/ui/Button';
import Table from '@c/ui/Table';
import Pagination from '@c/ui/Pagination';
import UnitAddForm from '@f/base/unit/UnitAdd.form';
import UnitSearchForm from '@f/base/unit/UnitSearch.form';
import ListMixin from '@m/List.mixin';
import { hgetStorage } from '@/util/htools.web';

export default {
	mixins: [ListMixin],
	components: {
		Table,
		Dialog,
		Pagination,
		Button,
		UnitAddForm,
		UnitSearchForm
	},
	data() {
		return {
			// 表格
			tableColumn: [
				{
					label: '名称',
					field: 'name',
					width: 200
				}
			],
			delTips: '',
			currentUserOrganization: null
		};
	},
	computed: {
		dialogTitle() {
			return this.editId === -1 ? '新增医院' : '编辑医院';
		}
	},
	watch: {
		pageIndex(newValue) {
			this.pageIndex = newValue;
			this.hospitalList();
		},
		pageSize(newValue) {
			this.pageSize = newValue;
			this.hospitalList();
		},
		isRefreshList(newValue) {
			if (newValue) {
				this.hospitalList();
			}
		},
		isRefreshListAll(newValue) {
			if (newValue) {
				if (this.pageIndex === 1) {
					this.hospitalList();
				} else {
					this.pageIndex = 1;
				}
			}
		}
	},
	mounted() {
		this.hospitalList();
		this.currentUserOrganization = hgetStorage('organization');
		setTimeout(() => {
			this.orgLogo = `${this.$envConfig.imgHttp}${this.$envConfig.imgFile.orglogo}/`;
			this.orgbusinesslicense = `${this.$envConfig.imgHttp}${this.$envConfig.imgFile.orgbusinesslicense}/`;
		}, 1000);
	},
	methods: {
		async hospitalList() {
			const dataJson = {
				pageNum: this.pageIndex,
				pageSize: this.pageSize,
				...this.searchForm
			};
			const res = await unitListService(dataJson);
			this.listMixin(res);
		},
		showDialogAddHandler() {
			this.dialogAddHandlerMixin();
		},
		async showDialogEditHandler() {
			const editId = this.dialogEditHandlerMixin();
			const dataJson = {
				unitsId: editId
			};
			const res = await unitDetailService(dataJson);
			this.selectData = [res];
			this.showDialogEditHandlerMixin();
		},
		async showDeleteHandler() {
			const ids = this.filterSelectIdsMixin();
			const dataJson = {
				ids: ids
			};
			await unitDeleteService(dataJson);
			this.isRefreshList = true;
		},
		async editSingleHandler(row) {
			const dataJson = {
				unitsId: row.id
			};
			const res = await unitDetailService(dataJson);
			this.editSingleHandlerMixin(res);
		},
		async deleteSingleHandler(row) {
			this.deleteSingleHandlerMixin(row);
		},
		searchFormHandler(searchForm) {
			this.searchFormHandlerMixin(searchForm);
			this.hospitalList();
		},
		editPasswordHandler(row) {
			this.isShowPasswordDialog = true;
			this.editId = row.id;
		}
	}
};
</script>
<style lang='less' scoped>
</style>
