import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 包装单位列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   measureUnit
 */
export const measureUnitListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				name: '',
				...data
			};
			const res = await hpostRequest('hwms/units/search', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 包装单位列表（所有）
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   measureUnit
 */
 export const measureUnitAllListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				...data
			};
			const res = await hgetRequest('hwms/units/all', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 包装单位添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:00:29
 */
export const measureUnitAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				name: '',
				...data
			};
			const res = await hpostRequest('hwms/units/add', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 包装单位编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:58
 */
export const measureUnitEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				name: 0,
				...data
			};
			const res = await hpostRequest('hwms/units/update', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 包装单位详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:59:21
 */
export const measureUnitDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				...data
			};
			const res = await hgetRequest('hwms/units/getById', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 包装单位删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:03
 */
export const measureUnitDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = [...data];
			const res = await hpostRequest('hwms/units/delete', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default measureUnitListService;
