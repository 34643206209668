import { hpostRequest, hgetRequest } from '@u/htools.axios.js';

/*
 *@Description: 医院等级列表
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:56:54   hospitalLevel
 */
export const hospitalLevelListService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				pageNum: 1,
				pageSize: 10,
				grade: '',
				...data
			};
			const res = await hpostRequest('hlzlong/hospitalGrade/hospitalGradeAllQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院等级添加
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:00:29
 */
export const hospitalLevelAddService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				grade: '',
				...data
			};
			const res = await hpostRequest('hlzlong/hospitalGrade/hospitalGradeAdd', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院等级编辑
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:58
 */
export const hospitalLevelEditService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				id: '',
				grade: 0,
				...data
			};
			const res = await hpostRequest('hlzlong/hospitalGrade/hospitalGradeUpdate', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院等级详情
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 09:59:21
 */
export const hospitalLevelDetailService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				hospitalGradeId: '',
				...data
			};
			const res = await hgetRequest('hlzlong/hospitalGrade/hospitalGradeByIdQuery', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};
/*
 *@Description: 医院等级删除
 *@MethodAuthor:  myw
 *@Date: 2020-12-17 10:12:03
 */
export const hospitalLevelDeleteService = data => {
	return new Promise(async resolve => {
		try {
			const dataJson = {
				ids: [],
				...data
			};
			const res = await hpostRequest('hlzlong/hospitalGrade/hospitalGradeDel', dataJson);
			resolve(res);
		} catch (error) {
			console.log(error);
		}
	});
};

export default hospitalLevelListService;
