import Login from '../views/Login.vue';
import v404 from '../views/404.vue';
import Layout from '../views/Layout.vue';
import Home from '../views/Home.vue';
/* 管理员管理 */
import AdminUserManager from '../views/adminManager/AdminUserManager';
import AdminRoleManager from '../views/adminManager/AdminRoleManager';
import AdminAuthorizeManager from '../views/adminManager/AdminAuthorizeManager';
import AdminMenuManager from '../views/adminManager/AdminMenuManager';
/* 系统管理 */
import UserManager from '../views/systemManager/UserManager';
import RoleManager from '../views/systemManager/RoleManager';
import AuthorizeManager from '../views/systemManager/AuthorizeManager';
import MenuManager from '../views/systemManager/MenuManager';
import Hospital from '../views/systemManager/Hospital';
import Company from '../views/systemManager/Company';
// 临时基础信息 属性信息表单 资产录入
import ZTempForm from '../views/systemManager/ZTempForm';

/* WebServer 临时测试websocket */
import WebServer from '../views/webServer/WebServer';
import jk from '../views/webServer/jk.vue';

/* 基础数据管理 */
import Equipment from '../views/baseData/Equipment';
import HospitalLevel from '../views/baseData/HospitalLevel';
import Fault from '../views/baseData/Fault';
import Norm from '../views/baseData/Norm';
import SystemParams from '../views/baseData/SystemParams';
import DeviceType from '../views/baseData/DeviceType';
import Unit from '../views/baseData/Unit';
import BSclass from '../views/baseData/BSclass';
import ManagerStyle from '../views/baseData/ManagerStyle';
import MeasureUnit from '../views/baseData/MeasureUnit';
import IssueReceiptType from '../views/baseData/IssueReceiptType';

/* 日志 */
import LoginLog from '../views/log/LoginLog';
import OperationLog from '../views/log/OperationLog';
import ServiceLog from '../views/log/ServiceLog';

const routes = [
	{
		path: '/login',
		name: 'Login',
		meta: {
			title: '登录'
		},
		component: Login
	},
	{
		path: '/ws', // 临时测试websocket
		name: 'WebServer',
		meta: {
			title: 'WebServer'
		},
		component: WebServer
	},
	{
		path: '/',
		redirect: '/home',
		name: 'Layout',
		component: Layout,
		children: [
			{
				path: 'system/jk',
				name: 'jk',
				meta: {
					title: '监控'
				},
				component: jk
			},
			{
				path: 'home',
				name: 'Home',
				meta: {
					title: '首页'
				},
				component: Home
			},
			{
				path: 'system/adminUserManager',
				name: 'AdminUserManager',
				meta: {
					title: '管理员用户管理'
				},
				component: AdminUserManager
			},
			{
				path: 'system/adminRoleManager',
				name: 'AdminRoleManager',
				meta: {
					title: '管理员角色管理'
				},
				component: AdminRoleManager
			},
			{
				path: 'system/adminAuthorizeManager',
				name: 'AdminAuthorizeManager',
				meta: {
					title: '管理员权限管理'
				},
				component: AdminAuthorizeManager
			},
			{
				path: 'system/adminMenuManager',
				name: 'AdminMenuManager',
				meta: {
					title: '管理员菜单管理'
				},
				component: AdminMenuManager
			},
			{
				path: 'system/userManager',
				name: 'UserManager',
				meta: {
					title: '用户管理'
				},
				component: UserManager
			},
			{
				path: 'system/roleManager',
				name: 'RoleManager',
				meta: {
					title: '角色管理'
				},
				component: RoleManager
			},
			{
				path: 'system/authorizeManager',
				name: 'AuthorizeManager',
				meta: {
					title: '权限管理'
				},
				component: AuthorizeManager
			},
			{
				path: 'system/menuManager',
				name: 'MenuManager',
				meta: {
					title: '菜单管理'
				},
				component: MenuManager
			},
			{
				path: 'system/hospital', // 医院管理
				name: 'Hospital',
				meta: {
					title: '医院管理'
				},
				component: Hospital
			},
			{
				path: 'system/company', // 维修公司
				name: 'Company',
				meta: {
					title: '维修公司'
				},
				component: Company
			},
			{
				path: 'system/zTempForm', // 资产录入
				name: 'ZTempForm',
				meta: {
					title: '资产录入'
				},
				component: ZTempForm
			},
			{
				path: 'baseData/equipment', // 设备管理
				name: 'Equipment',
				meta: {
					title: '设备管理'
				},
				component: Equipment
			},
			{
				path: 'baseData/hospitalLevel', // 设备管理
				name: 'HospitalLevel',
				meta: {
					title: '医院等级'
				},
				component: HospitalLevel
			},
			{
				path: 'baseData/unit', // 计量单位
				name: 'Unit',
				meta: {
					title: '计量单位'
				},
				component: Unit
			},
			{
				path: 'baseData/fault',
				name: 'Fault',
				meta: {
					title: '故障列表'
				},
				component: Fault
			},
			{
				path: 'baseData/norm',
				name: 'Norm',
				meta: {
					title: '标准型号'
				},
				component: Norm
			},
			{
				path: 'baseData/BSclass',
				name: 'BSclass',
				meta: {
					title: '68大小类'
				},
				component: BSclass
			},
			{
				path: 'baseData/systemParams',
				name: 'SystemParams',
				meta: {
					title: '系统参数'
				},
				component: SystemParams
			},
			{
				path: 'baseData/deviceType',
				name: 'DeviceType',
				meta: {
					title: '设备类型'
				},
				component: DeviceType
			},
			{
				path: 'baseData/managerStyle',
				name: 'ManagerStyle',
				meta: {
					title: '管理方式'
				},
				component: ManagerStyle
			},
			{
				path: 'baseData/measureUnit',
				name: 'MeasureUnit',
				meta: {
					title: '计量单位'
				},
				component: MeasureUnit
			},
			{
				path: 'baseData/issueReceiptType',
				name: 'IssueReceiptType',
				meta: {
					title: '出入库类型'
				},
				component: IssueReceiptType
			},
			{
				path: 'log/login', // 登录日志
				name: 'LoginLog',
				meta: {
					title: '登录日志'
				},
				component: LoginLog
			},
			{
				path: 'log/operation', // 操作日志
				name: 'OperationLog',
				meta: {
					title: '操作日志'
				},
				component: OperationLog
			},
			{
				path: 'log/service', // 错误日志
				name: 'ServiceLog',
				meta: {
					title: '错误日志'
				},
				component: ServiceLog
			},
			{
				path: '404',
				name: '404',
				meta: {
					title: '404'
				},
				component: v404
			}
		]
	},
	{
		path: '*',
		name: '404',
		meta: {
			title: '404'
		},
		component: v404
	}
];

export default routes;
